import {Button, Form} from 'react-bootstrap';
import react from 'react';
import {withRouter} from 'react-router-dom';
import React from 'react';
import axios from 'axios';
import {CREATE_UNIT} from '../../../env';

class NewUnit extends react.Component {
  constructor(props) {
    super(props);
    this.state = {
      name: null,
    }

    this.create = this.create.bind(this)
  }

  create() {
    if (this.state.name) {
      axios.post(CREATE_UNIT,
        { name: this.state.name },
        {
          headers: {
            Authorization: `bearer ${localStorage.getItem('token')}`
          }
        }
      ).then(r => {
        this.props.hide()
      }).catch(_ => null)
    }
  }


  render() {
    return (
      <Form>
        <Form.Group className='mb-3' controlId='formGroupEmail'>
          <Form.Control type='text' placeholder='Unit name'
                        onChange={value => this.setState({ name: value.target.value })}/>
        </Form.Group>
        <Button variant='primary btn-block' size='lg' onClick={this.create}>Create</Button>
      </Form>
    );
  }
}

export default withRouter(NewUnit);
