import {Route} from "react-router-dom";
import Permissions from "./Permissions";
import Roles from "./Roles";
import Admins from "./Admins";

export default function AccessControl () {
  return (
    <>
      <Route path='/access-control/permissions'>
        <Permissions/>
      </Route>
      <Route path='/access-control/roles'>
        <Roles/>
      </Route>
      <Route path='/access-control/admins'>
        <Admins/>
      </Route>
    </>
  )
}