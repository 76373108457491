import {Route, withRouter} from 'react-router-dom';
import react from 'react';
import Customers from './Customers/Customers';
import AddCustomers from './AddCustomers/AddCustomers';

class Myenergi extends react.Component
{
  render()
  {
    return (
      <>
        <Route path='/partners/myenergi/customers'>
          <Customers/>
        </Route>
        <Route path='/partners/myenergi/add-customers'>
          <AddCustomers/>
        </Route>
      </>
    );
  }
}

export default withRouter(Myenergi);
