import {Modal, Tab, Tabs} from 'react-bootstrap';
import react from 'react';
import {withRouter} from 'react-router-dom';
import NewInstruction from './NewInstruction';
import NewUnit from './NewUnit';

class NewCreation extends react.Component {
  constructor(props) {
    super(props);
    this.state = {}
  }


  render() {
    return (
      <Modal size="xl" show={this.props.show} onHide={this.props.hide}>
        <Modal.Header closeButton>
          <Modal.Title>
            Create instructions and units
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Tabs defaultActiveKey="instruction" transition={false} className="mb-3">
            <Tab eventKey="instruction" title="Instruction">
              <NewInstruction hide={this.props.hide}/>
            </Tab>
            <Tab eventKey="unit" title="Unit">
              <NewUnit hide={this.props.hide}/>
            </Tab>
          </Tabs>
        </Modal.Body>
      </Modal>
    );
  }
}

export default withRouter(NewCreation);
