const Auth = {
  is_authenticated()
  {
    return localStorage.getItem('is_authenticated') === 'true';
  },

  is_admin()
  {
    return localStorage.getItem('role') === 'admin';
  },

  login(token, role)
  {
    localStorage.setItem('is_authenticated', 'true');
    localStorage.setItem('role', role);
    localStorage.setItem('token', token);
  },

  authorize(history)
  {
  },

  force_logout(history)
  {
    localStorage.setItem('token', '');
    localStorage.setItem('is_authenticated', 'false');
    if (history.location.pathname === '/partners/login')
      return
    history.push('/login');
  }
}

export default Auth;
