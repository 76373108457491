//const ADMIN = 'http://localhost:5000'
const ADMIN = 'https://admin-api.orangepower.co.uk'
export const LOGIN = ADMIN + '/admin/login';
export const FORGOT_PASSWORD = ADMIN + '/admin/forgot-password';
export const RESET_PASSWORD = ADMIN + '/admin/reset-password';
export const PARTNER_LOGIN = ADMIN + '/admin/partner-login';
export const ADD_SCHEDULE = ADMIN + '/admin/add-schedule';
export const GET_USERS = ADMIN + '/admin/users';
export const GET_USER_DETAILS = ADMIN + '/admin/users';
export const GET_MYENERGI_CUSTOMERS = ADMIN + '/admin/myenergi-customers';
export const GET_EV_SUMMARY = ADMIN + '/admin/ev-summary';
export const GET_MYENERGI_CUSTOMER_METERING = ADMIN + '/admin/myenergi-customer-metering';
export const ADD_MYENERGI_CUSTOMERS = ADMIN + '/admin/add-myenergi-customers';
export const GET_SCHEDULES = ADMIN + '/admin/schedules';
export const DELETE_SCHEDULE = ADMIN + '/admin/delete-schedule';
export const DELETE_USER = ADMIN + '/admin/delete-user';
export const ZAPPI_GLOBAL_METERING = ADMIN + '/admin/myenergi-zappi-global-metering';
export const DOWNLOAD_METERING_XML = ADMIN + '/admin/download-metering-xml';
export const DOWNLOAD_EMAILS = ADMIN + '/admin/download-emails';
export const DOWNLOAD_EMAILS_NO_CONSENT = ADMIN + '/admin/download-emails-no-consent';
export const DOWNLOAD_MPANS = ADMIN + '/admin/download-mpans';
export const DOWNLOAD_POTENTIAL_MPANS = ADMIN + '/admin/download-potential-mpans';
export const DOWNLOAD_EMAIL_POINTS = ADMIN + '/admin/download-email-points';
export const DOWNLOAD_REDEEM_HISTORY_EMAILS = ADMIN + '/admin/download-redeem-history-emails';
export const SEND_NOTIFICATION = ADMIN + '/admin/send-notification';

// Instructions
export const GET_INSTRUCTIONS = ADMIN + '/instructions';
export const GET_INSTRUCTION = ADMIN + '/instructions';
export const DELETE_INSTRUCTION = ADMIN + '/instructions';
export const GET_INSTRUCTORS = ADMIN + '/instructors';
export const CREATE_INSTRUCTION = ADMIN + '/instructions/create-instruction';

// Units
export const UNITS = ADMIN + '/units';
export const GET_UNITS = UNITS;
export const CREATE_UNIT = UNITS + '/create-unit';

// Paypal
export const PAYPAL = ADMIN + '/paypal';
export const GET_PAYPAL_REDEEM_REQUESTS = PAYPAL + '/redeem-requests';
export const GET_PAYPAL_REDEEM_REQUESTS_HISTORY = PAYPAL + '/redeem-requests/history';
export const APPROVE_PAYPAL_REDEEM_REQUESTS = PAYPAL + '/redeem-requests/approve';
export const REJECT_PAYPAL_REDEEM_REQUESTS = PAYPAL + '/redeem-requests/reject';


export const GET_MPANS_ADDRESS =  ADMIN + '/mpans';
export const GET_METERING_BY_DATE =  ADMIN + '/admin/n3rgy-mpan-metering-by-date';
export const GET_ADMIN_UPLOADS =  ADMIN + '/admin/admin-uploads';
export const GET_USER_METRICS =  ADMIN + '/admin/get-metrics';
export const GET_FILE_URL =  ADMIN + '/admin/get-file';

export const GET_PERMISSIONS = ADMIN + '/admin/permissions';
export const CREATE_PERMISSION = ADMIN + '/admin/permissions';
export const UPDATE_PERMISSION = ADMIN + '/admin/permissions';
export const DELETE_PERMISSION = ADMIN + '/admin/permissions';

export const GET_ROLES = ADMIN + '/admin/roles';
export const CREATE_ROLE = ADMIN + '/admin/roles';
export const UPDATE_ROLE = ADMIN + '/admin/roles';
export const DELETE_ROLE = ADMIN + '/admin/roles';

export const GET_ADMINS = ADMIN + '/admin/admins';
export const CREATE_ADMIN = ADMIN + '/admin/admins';
export const UPDATE_ADMIN = ADMIN + '/admin/admins';
export const DELETE_ADMIN = ADMIN + '/admin/admins';
export const POST_UPDATED_EXPORT_MPANS_CSV = ADMIN + '/admin/export-mpans';

export const SCHEDULE_NOTIFICATION = ADMIN + '/admin/schedule-notification';
export const SEND_ATTEND_GRID_EVENTS_EMAIL = ADMIN + '/users/send-attend-grid-events-email';

// N3rgy
export const GET_N3RGY_METERING = ADMIN + '/admin/n3rgy-mpan-metering';
export const GET_N3RGY_BASELINE = ADMIN + '/admin/n3rgy-mpan-history';
export const GET_EVENTS = ADMIN + '/events';