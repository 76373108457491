import react from 'react';
import {
  Col,
  Container,
  Pagination,
  Row,
  Table,
} from 'react-bootstrap';
import axios from 'axios';
import {withRouter} from 'react-router-dom';
import React from 'react';
import {GET_PAYPAL_REDEEM_REQUESTS_HISTORY} from '../../../env';
import Auth from '../../../Auth';

class PaypalRedeemRequestsHistory extends react.Component {
  constructor(props) {
    super(props);
    this.state = {
      count: 0,
      redeem_requests_history: [],
      skip: 0,
      limit: 0,
      page_index: 0,
      gap: 10,
    }

    this.get_paypal_redeem_requests_history = this.get_paypal_redeem_requests_history.bind(this)
  }

  componentDidMount() {
    this.state.limit = this.state.gap;
    this.get_paypal_redeem_requests_history();
  }

  get_paypal_redeem_requests_history() {
    axios.get(`${GET_PAYPAL_REDEEM_REQUESTS_HISTORY}?skip=${this.state.skip}&limit=${this.state.limit}`,
      {
        headers: {
          Authorization: `bearer ${localStorage.getItem('token')}`
        }
      }).then(
      res => {
        this.setState({ count: res.data.count })
        this.setState({ redeem_requests_history: res.data.redeem_requests_history })
      }
    ).catch((error) => {
      if (error.response.status === 403) {
        Auth.force_logout(this.props.history.push)
      }
    })
  }

  get_pagination() {
    const pages = []
    let page_index = 1;
    for (let i = 0; i < this.state.count; i += this.state.gap) {
      if (this.state.page_index === i) {
        pages.push(<Pagination.Item active onClick={() => this.get_page(i)}>{page_index}</Pagination.Item>)
      } else {
        pages.push(<Pagination.Item onClick={() => this.get_page(i)}>{page_index}</Pagination.Item>)
      }
      ++page_index;
    }
    return pages;
  }

  get_page(skip) {
    console.log(skip)
    this.setState({
      skip: skip,
      limit: skip + this.state.gap,
      page_index: skip,
    }, () => {
      this.get_paypal_redeem_requests_history();
    })
  }

  render() {
    return (
      <Container>
        <Row className='mt-5'>
          <Col>
            <Table striped bordered hover variant='dark'>
              <thead>
              <tr>
                <th>Name</th>
                <th>Email</th>
                <th>Paypal Email</th>
                <th>Points</th>
                <th>Rewards</th>
                <th>Status</th>
                <th>Reason</th>
                <th>Date</th>
              </tr>
              </thead>
              <tbody>
              {this.state.redeem_requests_history.map((request, index) => (
                  <tr key={index}>
                    <td>{request.user?.name}</td>
                    <td>{request.user?.email}</td>
                    <td>{request.user?.paypal_email}</td>
                    <td>{request.points}</td>
                    <td>£{request.points}</td>
                    <td>{request.approved ? 'Approved' : 'Rejected'}</td>
                    <td>{request.reason}</td>
                    <td>{request.createdAt}</td>
                  </tr>
                )
              )}
              </tbody>
            </Table>
          </Col>
          <Pagination>
            {this.get_pagination()}
          </Pagination>
        </Row>
      </Container>
    )
  }
}

export default withRouter(PaypalRedeemRequestsHistory);
