import react from 'react';
import {
  Button,
  Col,
  Container,
  Pagination,
  Row,
  Table,
} from 'react-bootstrap';
import axios from 'axios';
import {withRouter} from 'react-router-dom';
import React from 'react';
import {
  APPROVE_PAYPAL_REDEEM_REQUESTS,
  GET_PAYPAL_REDEEM_REQUESTS,
  REJECT_PAYPAL_REDEEM_REQUESTS
} from '../../../env';
import Auth from '../../../Auth';

class PaypalRedeemRequests extends react.Component {
  constructor(props) {
    super(props);
    this.state = {
      count: 0,
      redeem_requests: [],
      skip: 0,
      limit: 0,
      page_index: 0,
      gap: 10,
    }

    this.get_paypal_redeem_requests = this.get_paypal_redeem_requests.bind(this)
  }

  componentDidMount() {
    this.state.limit = this.state.gap;
    this.get_paypal_redeem_requests();
  }

  get_paypal_redeem_requests() {
    axios.get(`${GET_PAYPAL_REDEEM_REQUESTS}?skip=${this.state.skip}&limit=${this.state.limit}`,
      {
        headers: {
          Authorization: `bearer ${localStorage.getItem('token')}`
        }
      }).then(
      res => {
        this.setState({ count: res.data.count })
        this.setState({ redeem_requests: res.data.redeem_requests })
      }
    ).catch((error) => {
      if (error.response.status === 403) {
        Auth.force_logout(this.props.history.push)
      }
    })
  }

  approve(id) {
    axios.post(`${APPROVE_PAYPAL_REDEEM_REQUESTS}/${id}`,
      null,
      {
        headers: {
          Authorization: `bearer ${localStorage.getItem('token')}`
        }
      }).then(_ => this.get_paypal_redeem_requests()
    ).catch((error) => {
      this.get_paypal_redeem_requests();
      if (error.response.status === 403) {
        Auth.force_logout(this.props.history.push)
      }
    })
  }

  reject(id) {
    axios.post(`${REJECT_PAYPAL_REDEEM_REQUESTS}/${id}`,
      null,
      {
        headers: {
          Authorization: `bearer ${localStorage.getItem('token')}`
        }
      }).then(_ => this.get_paypal_redeem_requests()
    ).catch((error) => {
      this.get_paypal_redeem_requests();
      if (error.response.status === 403) {
        Auth.force_logout(this.props.history.push)
      }
    })
  }

  get_pagination() {
    const pages = []
    let page_index = 1;
    for (let i = 0; i < this.state.count; i += this.state.gap) {
      if (this.state.page_index === i) {
        pages.push(<Pagination.Item active onClick={() => this.get_page(i)}>{page_index}</Pagination.Item>)
      } else {
        pages.push(<Pagination.Item onClick={() => this.get_page(i)}>{page_index}</Pagination.Item>)
      }
      ++page_index;
    }
    return pages;
  }

  get_page(skip) {
    this.setState({
      skip: skip,
      limit: skip + this.state.gap,
      page_index: skip,
    }, () => {
      this.get_paypal_redeem_requests();
    })
  }

  render() {
    return (
      <Container>
        <Row className='mt-5'>
          <Col>
            <Table striped bordered hover variant='dark'>
              <thead>
              <tr>
                <th>Name</th>
                <th>Email</th>
                <th>Paypal Email</th>
                <th>Requested</th>
                <th>Points</th>
                <th>Rewards</th>
                <th>Actions</th>
              </tr>
              </thead>
              <tbody>
              {this.state.redeem_requests.map((request, index) => (
                  <tr key={index}>
                    <td>{request.name}</td>
                    <td>{request.email}</td>
                    <td>{request.paypal_email}</td>
                    <td>{request.is_redeem_requested ? 'Yes' : 'No'}</td>
                    <td>{request.points}</td>
                    <td>£{request.points}</td>
                    <td>
                      <Button className='m-1' variant='success' onClick={() => this.approve(request._id)}>
                        Approve
                      </Button>
                      <Button className='m-1' variant='danger' onClick={() => this.reject(request._id)}>
                        Reject
                      </Button>
                    </td>
                  </tr>
                )
              )}
              </tbody>
            </Table>
          </Col>
          <Pagination>
            {this.get_pagination()}
          </Pagination>
        </Row>
      </Container>
    )
  }
}

export default withRouter(PaypalRedeemRequests);
