import react from 'react';
import Myenergi from './Myenergi/Myenergi';
import {Route, withRouter} from 'react-router-dom';
import PartnerLogin from './PartnerLogin/PartnerLogin';

class Partners extends react.Component
{
  render()
  {
    return (
      <>
        <Route path='/partners/myenergi/*'>
          <Myenergi/>
        </Route>
        <Route path='/partners/login'>
          <PartnerLogin/>
        </Route>
      </>
    );
  }
}

export default withRouter(Partners);
