import react from 'react';
import {
    Col,
    Container,
    Row,
    Table,
} from 'react-bootstrap';

import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
  } from 'chart.js';
  import { Line } from 'react-chartjs-2';

import axios from 'axios';
import { withRouter } from 'react-router-dom';
import React from 'react';
import { GET_USER_METRICS } from '../../../env';
import Auth from '../../../Auth';
  
  ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
  );
  
  export const options = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
      },
      title: {
        display: true,
        text: 'User Hourly Chart',
      },
    },
  };

class UserTrends extends react.Component {
    constructor(props) {
        super(props);
        this.state = {
            user_data: [],
        }
        this.make_chart_data = this.make_chart_data.bind(this)
        this.get_user_metrics = this.get_user_metrics.bind(this)
    }

    componentDidMount() {
        this.state.limit = this.state.gap;
        this.get_user_metrics();
    }
    make_chart_data(){

        const labels = this.state.user_data.map(u=>u.date).reverse();

 const data = {
  labels,
  datasets: [
    {
      label: 'Total',
      data: this.state.user_data.map(u=>u.total_users).reverse(),
      borderColor: 'rgb(255, 99, 132)',
      backgroundColor: 'rgba(255, 99, 132, 0.5)',
    },
    {
      label: 'Mpan users',
      data: this.state.user_data.map(u=>u.consented_mpan_users).reverse(),
      borderColor: 'rgb(53, 162, 235)',
      backgroundColor: 'rgba(53, 162, 235, 0.5)',
    },
  ],
};
return data;
    }




    get_user_metrics() {
        axios.get(`${GET_USER_METRICS}`,
            {
                headers: {
                    Authorization: `bearer ${localStorage.getItem('token')}`
                },
                params: {
                    with_user: true,
                }
            }).then(
                (res) => {
                    this.setState({ user_data: res.data });
                }
            ).catch((error) => {
                if (error.response && error.response.status === 403) {
                    Auth.force_logout(this.props.history.push)
                }

            })
    }





    render() {
        return (
            <Container>
<Line options={options} data={this.make_chart_data()} />;
                <Row className='mt-5'>
                    <Col>
                        <Table striped bordered hover variant='dark'>
                            <thead>
                                <tr>
                                    <th>Date</th>
                                    <th>Total Users</th>
                                    <th>Total Mpan Users</th>
                                    <th>Mpan Users</th>

                                </tr>
                            </thead>
                            <tbody>
                                {
                                    this.state.user_data.map((data, index) => {

                                        return (<tr key={index}>
                                            <td>{data.date}</td>
                                            <td>{data.total_users}</td>
                                            <td>{data.total_mpan_users}</td>
                                            <td>{data.consented_mpan_users}</td>
                                        </tr>
                                        )
                                    })
                                }


                            </tbody>
                        </Table>
                    </Col>
                </Row>
            </Container>
        )
    }
}

export default withRouter(UserTrends);
