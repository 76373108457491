import react from 'react';
import React from 'react';
import ev_img from './ev.png'
import {withRouter} from 'react-router-dom';
import {Card, Container} from 'react-bootstrap';
import axios from 'axios';
import {GET_EV_SUMMARY} from '../../../env';

class EvSummary extends react.Component {
  constructor(props) {
    super(props);
    this.state = {
      total_customers: 0,
      earnings: 0,
    }
    this.get_ev_summary = this.get_ev_summary.bind(this)
  }

  componentWillMount() {
    this.get_ev_summary();
  }

  get_ev_summary() {
    axios.get(GET_EV_SUMMARY, { headers: { Authorization: `bearer ${localStorage.getItem('token')}`, } }).then(res => {
      this.setState({total_customers: res.data.total_customers})
      this.setState({earnings: res.data.earnings})
    })
  }

  render() {
    const col2_style = { height: '9rem' }
    const col2_card_style = { width: '21rem', height: '8rem', 'border-radius': '25px' }
    const col3_style = { height: '9rem' }
    const col3_card_style = { width: '18rem', height: '8rem', 'border-radius': '25px', 'margin-left': '1em' }

    return (
      <Container>
        <style>{`body{background-color: #121212;}`}</style>
        <div className='mt-2'>
          <div className="row">
            <div className="col-md-6">
              <img style={{ height: 'auto', width: '100%' }} src={ev_img} alt='EV'/>
            </div>
            <div className="col-md-3 ml-5">
              <div className="row mt-3" style={col2_style}>
                <div className="w-50">
                  <Card style={col2_card_style}>
                    <Card.Body>
                      <Card.Title>234 kWh</Card.Title>
                      <Card.Subtitle className="mb-2 text-muted">Energy Consumed</Card.Subtitle>
                      <Card.Text>111 kWh from Grid | 123 kWh from House</Card.Text>
                    </Card.Body>
                  </Card>
                </div>
              </div>
              <div className="row" style={col2_style}>
                <div className="col-md-12">
                  <Card style={col2_card_style}>
                    <Card.Body>
                      <Card.Title>127 kWh</Card.Title>
                      <Card.Subtitle className="mb-2 text-muted">Energy Output</Card.Subtitle>
                      <Card.Text>97 kWh to Grid | 30 kWh to House</Card.Text>
                    </Card.Body>
                  </Card>
                </div>
              </div>
              <div className="row" style={col2_style}>
                <div className="col-md-12">
                  <Card style={col2_card_style}>
                    <Card.Body>
                      <Card.Title>234 kWh</Card.Title>
                      <Card.Subtitle className="mb-2 text-muted">Energy Stored</Card.Subtitle>
                      <Card.Text>128 kWh of which available</Card.Text>
                    </Card.Body>
                  </Card>
                </div>
              </div>
            </div>
            <div className="col-md-2 mt-3">
              <div className="row" style={col3_style}>
                <div className="col-md-12">
                  <Card style={col3_card_style}>
                    <Card.Body>
                      <Card.Title><i className="bi bi-piggy-bank-fill"/>EVs</Card.Title>
                      <Card.Subtitle className="mb-2 text-muted">{this.state.total_customers}</Card.Subtitle>
                      <Card.Text>{this.state.total_customers} Total</Card.Text>
                    </Card.Body>
                  </Card>
                </div>
              </div>
              <div className="row" style={col3_style}>
                <div className="col-md-12">
                  <Card style={col3_card_style}>
                    <Card.Body>
                      <Card.Title>Earnings</Card.Title>
                      <Card.Subtitle className="mb-2 text-muted">£{this.state.earnings.toFixed(2)}</Card.Subtitle>
                      <Card.Text>Projected £{this.state.earnings.toFixed(2)}</Card.Text>
                    </Card.Body>
                  </Card>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Container>
    );
  }
}

export default withRouter(EvSummary);
