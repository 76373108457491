import {Button, FloatingLabel, Form, Modal, Nav, Toast} from 'react-bootstrap';
import react from 'react';
import React from 'react';
import axios from 'axios';
import {ADD_SCHEDULE} from '../../env';
import {withRouter} from 'react-router-dom';
import Auth from '../../Auth';

class EventAdder extends react.Component
{
  constructor(props)
  {
    super(props);
    this.state = {
      postcode: '',
      start: 0,
      end: 0,
      show: false,
      show_toast: false,
      toast_msg: '',
      duration: '0:00:00:00'
    }
    this.setState({ show: this.props.show })
    this.add_schedule = this.add_schedule.bind(this)
    this.start = this.start.bind(this)
    this.end = this.end.bind(this)
    this.compose_duration = this.compose_duration.bind(this)
  }

  compose_duration()
  {
    const duration = this.state.end - this.state.start;
    const days = Math.floor(duration / 86400000);
    let hours = Math.floor((duration % 86400000) / 3600000);
    let minutes = Math.floor((duration % 3600000) / 60000);
    let seconds = Math.floor((duration % 60000) / 1000);
    hours = String(hours).padStart(2, '0')
    minutes = String(minutes).padStart(2, '0')
    seconds = String(seconds).padStart(2, '0')
    this.setState({ duration: `${days}:${hours}:${minutes}:${seconds}` });
  }

  start(value)
  {
    this.setState({ start: new Date(value).getTime() })
    this.compose_duration();
  }

  end(value)
  {
    this.setState({ end: new Date(value).getTime() })
    this.compose_duration();
  }

  add_schedule()
  {
    const data = {
      postcode: this.state.postcode,
      start: this.state.start,
      end: this.state.end
    }
    axios.post(ADD_SCHEDULE, data, { headers: { Authorization: `bearer ${localStorage.getItem('token')}`, } }).then(
      () =>
      {
        this.setState({ show: false })
      }
    ).catch((error) =>
    {
      if (error.response.status === 401) Auth.force_logout(this.props.history.push);
      else
      {
        this.setState({ toast_msg: error.response.data })
        this.setState({ show_toast: true })
      }
    })
  }

  render()
  {
    return (
      <>
        <Nav.Item as="li">
          <Button onClick={() => this.setState({ show: true })}>Add Event</Button>
        </Nav.Item>
        <Modal size='sm' show={this.state.show} onHide={() => this.setState({ show: false })} aria-labelledby="example-modal-sizes-title-lg">
          <Modal.Header closeButton>
            <Modal.Title id="example-modal-sizes-title-lg">
              <h3>{this.state.duration}</h3>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <FloatingLabel controlId="floatingInput" label="Postcode" className="mb-3">
              <Form.Control type="text" placeholder="Postcode"
                            onChange={value => this.setState({ postcode: value.target.value })}/>
            </FloatingLabel>
            <FloatingLabel controlId="floatingInput" label="Start" className="mb-3">
              <Form.Control type="datetime-local" placeholder="Start"
                            onChange={value => this.start(value.target.value)}/>
            </FloatingLabel>
            <FloatingLabel controlId="floatingInput" label="End" className="mb-3">
              <Form.Control type="datetime-local" placeholder="End"
                            onChange={value => this.end(value.target.value)}/>
            </FloatingLabel>
          </Modal.Body>
          <Toast show={this.state.show_toast}>
            <Toast.Header>
              <strong className="me-auto">Error</strong>
            </Toast.Header>
            <Toast.Body>{this.state.toast_msg}</Toast.Body>
          </Toast>
          <Button style={{ 'border-radius': 0 }} size='lg' variant='primary' onClick={this.add_schedule}>Add</Button>
        </Modal>
      </>
    );
  }
}

export default withRouter(EventAdder);
