import {Button, FormControl, InputGroup, Modal, Nav, Row, Spinner} from 'react-bootstrap';
import react from 'react';
import axios from 'axios';
import {ZAPPI_GLOBAL_METERING} from '../../../../env';
import Chart from 'react-apexcharts'

class ZappiGlobalMetering extends react.Component
{
  chart = null;

  constructor(props)
  {
    super(props);
    this.state = {
      metering: [],
      raw_metering: [],
      filtered_raw_metering: [],
      options: {},
      series: [],
      date: [],
      upwards: [],
      downwards: [],
      start: '',
      end: '',
      loading: true,
      show: true
    }
    this.get_metering = this.get_metering.bind(this);
    this.configure_chart = this.configure_chart.bind(this);
    this.filter = this.filter.bind(this);
    this.reset = this.reset.bind(this);
  }

  get_upwards_downwards(energy)
  {
    if (energy < 1400)
    {
      return { upwards: 0, downwards: 0 }
    } else if (energy >= 1400 && energy <= 1700)
    {
      return { upwards: 0, downwards: 5500 }
    } else if (energy > 1700 && energy <= 7500)
    {
      return { upwards: energy - 1500, downwards: 7500 - energy }
    } else
    {
      return { upwards: energy - 1500, downwards: 0 }
    }
  }

  componentDidMount()
  {
    this.get_metering();
  }

  get_metering()
  {
    axios.get(ZAPPI_GLOBAL_METERING, { headers: { Authorization: `bearer ${localStorage.getItem('token')}`, } }).then(
      res =>
      {
        const all = res.data.metering;

        let index = 0;
        let max = 0;
        for (let i = 0; i < all.length; ++i)
        {
          if (all[i].zappi._metering.count > max)
          {
            max = all[i].zappi._metering.count;
            index = i;
          }
        }

        const raw_metering = [];
        for (let i = 0; i < all[index].zappi._metering.metering.length; ++i)
        {
          const upwards_downwards = this.get_upwards_downwards(all[index].zappi._metering.metering[i].energy);
          raw_metering.push({
            energy: all[index].zappi._metering.metering[i].energy,
            time: new Date(all[index].zappi._metering.metering[i].creation_date).getTime(),
            upwards: upwards_downwards.upwards,
            downwards: upwards_downwards.downwards
          })
        }
        for (let i = 0; i < all.length; ++i)
        {
          if (i === index) continue;
          for (let j = 0; j < all[i].zappi._metering.count; ++j)
          {
            const pos = max - all[i].zappi._metering.count + j
            const upwards_downwards = this.get_upwards_downwards(all[i].zappi._metering.metering[j].energy);
            raw_metering[pos].energy += all[i].zappi._metering.metering[j].energy;
            raw_metering[pos].upwards += upwards_downwards.upwards;
            raw_metering[pos].downwards += upwards_downwards.downwards;
          }
        }
        this.setState({ raw_metering, loading: false }, this.configure_chart)
      }
    )
  }

  reset()
  {
    this.get_metering()
  }

  filter()
  {
    let start = this.state.start
    let end = this.state.end
    const filtered_raw_metering = []

    start = new Date(start)
    end = new Date(end)
    for (let i = 0; i < this.state.raw_metering.length; ++i)
    {
      const time = this.state.raw_metering[i].time
      if (time >= new Date(start) && time <= new Date(end))
      {
        filtered_raw_metering.push(this.state.raw_metering[i])
      }
    }
    this.setState({ filtered_raw_metering, loading: false }, () => this.configure_chart(true))
  }

  configure_chart(filter = false)
  {
    if (!this.state.loading)
    {
      let raw_metering;
      if (filter)
      {
        raw_metering = this.state.filtered_raw_metering;
      } else
      {
        raw_metering = this.state.raw_metering
      }
      const energy = [];
      const time = [];
      const upwards = [];
      const downwards = [];
      for (let i = 0; i < raw_metering.length; ++i)
      {
        energy.push(raw_metering[i].energy)
        const timestamp = new Date(raw_metering[i].time)
        time.push(`${timestamp.getHours()}`.padStart(2, '0') + ':' + `${timestamp.getMinutes()}`.padStart(2, '0'))
        upwards.push(raw_metering[i].upwards)
        downwards.push(raw_metering[i].downwards)
      }
      // console.log(raw_metering)
      this.setState({
        options: {
          markers: {
            size: 0
          },
          dataLabels: {
            enabled: false
          },
          chart: {
            id: this.chart,
            animations: {
              enabled: false
            },
          },
          xaxis: {
            categories: time.slice(energy.length - 336, energy.length)
          }
        },
        series: [
          {
            name: 'energy',
            type: 'area',
            data: energy.slice(energy.length - 336, energy.length)
          }, {
            name: 'downwards',
            type: 'column',
            data: downwards.slice(energy.length - 336, energy.length)
          }, {
            name: 'upwards',
            type: 'column',
            data: upwards.slice(energy.length - 336, energy.length)
          }]
      })
      // const max = metering.length
      // if (!filter)
      // {
      //   metering = metering.slice(max - 48, max)
      //   date = date.slice(max - 48, max)
      //   upwards = upwards.slice(max - 48, max)
      //   downwards = downwards.slice(max - 48, max)
      // }
      //
      //   const chart_canvas = ReactDOM.findDOMNode(this.chart);
      //   new Chart(chart_canvas, {
      //       type: "bar",
      //       data: {
      //         labels: time,
      //         datasets: [
      //           {
      //             label: "Metering (WATTS)",
      //             data: energy,
      //             type: "line",
      //             borderColor: 'rgb(54,75,75)',
      //             pointStyle: "circle",
      //           },
      //           {
      //             label: "Downwards",
      //             data: downwards,
      //             type: "bar",
      //             backgroundColor: "#DE924B"
      //           },
      //           {
      //             label: "Upwards",
      //             data: upwards,
      //             type: "bar",
      //             backgroundColor: "#71A4F1"
      //           },
      //         ],
      //       },
      //       options: {
      //         zoom: {
      //           enabled: true,
      //           mode: 'x',
      //         },
      //         pan: {
      //           enabled: true,
      //           mode: 'x',
      //         },
      //         plugins: {
      //           title: {
      //             display: true,
      //             text: 'Metering'
      //           },
      //           zoom: {
      //             enabled: true,
      //             drag: true,
      //             mode: 'x',
      //           },
      //           pan: {
      //             enabled: true,
      //             mode: 'x',
      //           },
      //         },
      //         responsive: true,
      //         scales: {
      //           x: {
      //             stacked: true,
      //           },
      //           y: {
      //             stacked: true
      //           }
      //         }
      //       }
      //     },
      //   );
      //
    }

  }

  render()
  {
    return (
      <Modal size="xl" show={this.state.show} onHide={this.props.hide}>
        <Modal.Header closeButton>
          <Modal.Title>
            <h2>Zappi Global Metering</h2>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Nav as="ul">
            <Nav.Item as="li">
              <InputGroup className="mb-2">
                <InputGroup.Text>Start</InputGroup.Text>
                <FormControl type='date' onChange={(e) => this.setState({ start: e.target.value })}
                             id="inlineFormInputGroup" placeholder="Username"/>
              </InputGroup>
            </Nav.Item>
            <Nav.Item as="li">
              <InputGroup className="mb-2">
                <InputGroup.Text>End</InputGroup.Text>
                <FormControl type='date' onChange={(e) => this.setState({ end: e.target.value })}
                             id="inlineFormInputGroup" placeholder="Username"/>
              </InputGroup>
            </Nav.Item>
            <Nav.Item as="li">
              <Button className='mx-3' onClick={this.filter}>Filter</Button>
              <Button className='mx-3' onClick={this.reset}>Reset</Button>
            </Nav.Item>
          </Nav>
          {this.state.loading ?
            <Spinner animation="border" role="status">
              <span className="visually-hidden">Loading...</span>
            </Spinner> : (<Row>
              <Chart options={this.state.options} series={this.state.series} type="area"/>
            </Row>)}
        </Modal.Body>
      </Modal>
    )
  }
}

export default ZappiGlobalMetering;
