import {Button, Col, Row, Table, Form, Container} from 'react-bootstrap';
import axios from 'axios'
import {GET_MYENERGI_CUSTOMERS} from '../../../../env';
import CustomerInformation  from './CustomerInformation';
import react from 'react';
import {withRouter} from 'react-router-dom';
import Auth from '../../../../Auth';
import ZappiGlobalMetering from './ZappiGlobalMetering';

class Customers extends react.Component
{
  constructor(props)
  {
    super(props);
    this.state = {
      customers: [],
      filtered_customers: [],
      customer: {},
      show_customer_info: false,
      show_zappi_global_metering: false,
      filter: ''
    };
    this.hide_customer_info = this.hide_customer_info.bind(this)
    this.hide_zappi_global_metering = this.hide_zappi_global_metering.bind(this)
  }

  componentDidMount()
  {
    this.get_myenergi_customers();
  }

  get_myenergi_customers()
  {
    axios.get(GET_MYENERGI_CUSTOMERS, { headers: { Authorization: `bearer ${localStorage.getItem('token')}`, } }).then(
      res =>
      {
        this.setState({ customers: res.data.customers })
        this.setState({ filtered_customers: res.data.customers })
      }
    ).catch(() =>
    {
      Auth.force_logout(this.props.history.push)
    })
  }

  hide_customer_info()
  {
    this.setState({ show_customer_info: false })
  }

  hide_zappi_global_metering()
  {
    this.setState({ show_zappi_global_metering: false })
  }

  display_user_details(id)
  {
    this.setState({ customer: this.state.filtered_customers[id] });
    this.setState({ show_customer_info: true })
  }

  filter(key)
  {
    const filter = this.state.filter.split(':')
    this.setState({ filtered_customers: this.state.customers })
    try
    {
      if (key.charCode === 13)
      {
        this.setState({ filtered_customers: this.state.customers.filter(obj => obj[filter[0]].includes(filter[1])) })
      }
    } catch (_)
    {
      this.setState({ filtered_customers: this.state.customers })
    }
  }

  async delete_user(user_id)
  {
    // const res = await axios.post(DELETE_USER_API, {user_id});
    // this.forceUpdate();
  }

  render()
  {
    return (
      <Container>
        <Row className='mt-5'>
          <Form.Control onKeyPress={key => this.filter(key)} className='mb-3' size="lg" type="text" placeholder="Filter"
                        onChange={value => this.setState({ filter: value.target.value })}/>
          <Button className='mb-2' variant='primary'
                  onClick={() => this.setState({show_zappi_global_metering: true})}>Zappi Global Metering</Button>
          <Col>
            <Table striped bordered hover variant='dark'>
              <thead>
              <tr>
                <th>Email</th>
                <th>Hub Serial Number</th>
                <th>Postcode</th>
                <th>Action</th>
              </tr>
              </thead>
              <tbody>
              {this.state.filtered_customers.map((customer, index) => (
                  <tr>
                    <td>{customer.email}</td>
                    <td>{customer.hub_serial_no}</td>
                    <td>{customer.postcode}</td>
                    <td>
                      <Button className='mb-2' variant='primary'
                              onClick={() => this.display_user_details(index)}>Details</Button>
                      <Button variant='danger' onClick={() => this.delete_user(customer._id)}>Delete</Button>
                    </td>
                  </tr>
                )
              )}
              </tbody>
            </Table>
          </Col>
          {this.state.show_customer_info === true &&
          <CustomerInformation hide={this.hide_customer_info} customer={this.state.customer}/>}
          {this.state.show_zappi_global_metering === true &&
          <ZappiGlobalMetering hide={this.hide_zappi_global_metering}/>}
        </Row>
      </Container>
    );
  }
}

export default withRouter(Customers);
