import axios from 'axios'
import FileDownload from 'js-file-download';

import {
    DOWNLOAD_EMAIL_POINTS,
    DOWNLOAD_EMAILS,
    DOWNLOAD_EMAILS_NO_CONSENT,
    DOWNLOAD_METERING_XML,
    DOWNLOAD_MPANS,
    DOWNLOAD_POTENTIAL_MPANS,
    DOWNLOAD_REDEEM_HISTORY_EMAILS,
    GET_USERS,
    SCHEDULE_NOTIFICATION, SEND_ATTEND_GRID_EVENTS_EMAIL,
} from '../../env';
import react from 'react';
import {Doughnut} from 'react-chartjs-2'
import {Button, Col, Form, FormControl, InputGroup, Row, Table} from 'react-bootstrap';
import {withRouter} from 'react-router-dom';
import Auth from '../../Auth';
import React from 'react';
import {toast, ToastContainer} from "react-toastify";

class Dashboard extends react.Component {
  constructor(props) {
    super(props);
    this.state = {
      users: [],
      user: {},
      participation: [],
      filter: '',
      value: 0,
      postcode: '',
      title: '',
      body: '',
      mpans: [],
      time: '',
      time_from: '',
      time_to: '',
      target: '',
      server: '',
    };

    this.apis = ['swarco.leuchars', 'swarco.st andrews', 'myenergi']
    this.selected_apis = []

    this.handle_checkbox_change = this.handle_checkbox_change.bind(this)
    this.download = this.download.bind(this)
    this.download_emails = this.download_emails.bind(this)
    this.download_emails_no_consent = this.download_emails_no_consent.bind(this)
    this.download_mpans = this.download_mpans.bind(this)
    this.download_potential_mpans = this.download_potential_mpans.bind(this)
    this.download_email_points = this.download_email_points.bind(this)
    this.download_redeem_history_emails = this.download_redeem_history_emails.bind(this)
    this.schedule_notification = this.schedule_notification.bind(this)
    this.send_attend_grid_events_email = this.send_attend_grid_events_email.bind(this)
    this.upload_csv = this.upload_csv.bind(this)
  }

  componentDidMount() {
    this.get_users();
  }

  download() {
    axios.post(DOWNLOAD_METERING_XML, {
      apis: this.selected_apis, date: this.state.filter
    }, {headers: {Authorization: `bearer ${localStorage.getItem('token')}`,}}).then(res => {
      const element = document.createElement("a");
      const file = new Blob([res.data], {type: 'text/plain'});
      element.href = URL.createObjectURL(file);
      element.download = this.selected_apis.join('-') + " metering.xml";
      document.body.appendChild(element);
      element.click();
    })
  }

  async download_emails() {
    axios.get(DOWNLOAD_EMAILS, {headers: {Authorization: `bearer ${localStorage.getItem('token')}`,}})
      .then(response => {
        FileDownload(response.data, 'emails.csv');
      })
  }

  async download_emails_no_consent() {
    await axios.get(DOWNLOAD_EMAILS_NO_CONSENT, {headers: {Authorization: `bearer ${localStorage.getItem('token')}`,}})
      .then(response => {
        FileDownload(response.data, 'emails_no_consent.csv');
      })
  }

  async download_mpans() {
    await axios.get(DOWNLOAD_MPANS, {headers: {Authorization: `bearer ${localStorage.getItem('token')}`,}})
      .then(response => {
        FileDownload(response.data, 'mpans.csv');
      })
  }

  async download_email_points() {
    await axios.get(`${DOWNLOAD_EMAIL_POINTS}/${this.state.value}`, {headers: {Authorization: `bearer ${localStorage.getItem('token')}`,}})
      .then(response => {
        FileDownload(response.data, 'email_points.csv');
      })
  }


  async download_potential_mpans() {
    await axios.get(DOWNLOAD_POTENTIAL_MPANS, {headers: {Authorization: `bearer ${localStorage.getItem('token')}`,}})
      .then(response => {
        FileDownload(response.data, 'potential_mpans.csv');
      })
  }

  async download_redeem_history_emails() {
    await axios.get(DOWNLOAD_REDEEM_HISTORY_EMAILS, {headers: {Authorization: `bearer ${localStorage.getItem('token')}`,}})
      .then(response => {
        FileDownload(response.data, 'redeem_history_emails.csv');
      })
  }

  schedule_notification() {
    axios.post(SCHEDULE_NOTIFICATION, {
      mpans: this.state.mpans,
      title: this.state.title,
      body: this.state.body,
      time: this.state.time,
      target: this.state.target,
    }, {headers: {Authorization: `bearer ${localStorage.getItem('token')}`,}})
      .then(r => {
        toast(r.data)
      }).catch(e => {
        toast(`${e.response.data.error}: ${e.response.data.detail}`)
    })
  }
  send_attend_grid_events_email() {
    axios.post(SEND_ATTEND_GRID_EVENTS_EMAIL, {
      mpans: this.state.mpans,
      time_from: this.state.time_from,
      time_to: this.state.time_to,
      server: this.state.server,
    }, {headers: {Authorization: `bearer ${localStorage.getItem('token')}`,}})
      .then(r => {
        toast(r.data)
      }).catch(e => {
        toast(`${e.response.data.error}: ${e.response.data.detail}`)
    })
  }


  get_users() {
    axios.get(GET_USERS, {headers: {Authorization: `bearer ${localStorage.getItem('token')}`,}}).then(res => {
      const users = res.data
      for (let i = 0; i < users.length; ++i) {
        try {
          users[i].apis = users[i].apis.join(' / ')
        } catch (e) {
        }
      }
      let attend = 0
      let not_attend = 0

      for (const user of users) {
        if (user.auto_attend_grid_events === true) {
          ++attend;
        } else {
          ++not_attend;
        }
      }
      this.setState({users})
      this.setState({participation: [attend, not_attend]})
    }).catch((e) => {
      console.log(JSON.stringify(e.response))
      Auth.force_logout(this.props.history.push)
    })
  }

  handle_checkbox_change(event, api) {
    const checked = event.target.checked;
    if (checked) {
      this.selected_apis.push(api)
    } else {
      const index = this.selected_apis.indexOf(api)
      this.selected_apis.splice(index, index + 1)
    }
  }

  upload_csv(file) {
    const reader = new FileReader();
    reader.readAsText(file);
    this.setState({mpans: []})
    reader.onload = () => {
      let data = ''
      for (let i = 0; i < reader.result.length; ++i) {
        if (reader.result[i] !== '\n') {
          data += reader.result[i];
        } else {
          data = data.split(',')
          this.state.mpans.push(data[0].replace(/ /g, '').trim())
          data = ''
        }
      }
      this.setState({mpans: [...this.state.mpans]})
    }
    reader.onerror = () => {
    }
  }

  render() {
    const data = {
      labels: ['Attending', 'Not Attending',], datasets: [{
        label: 'Grid Rewards Participation',
        data: this.state.participation,
        backgroundColor: ['rgb(54, 162, 235)', 'rgb(255, 99, 132)',],
        hoverOffset: 4
      }]
    };
    return (
      <div>
      <Row >
        <Col lg={5} className='mt-5 ml-10  mr-10 mx-auto float-none shadow p-3 mb-5 bg-body rounded'>
          <h3 className='text-center'>Attendance</h3>
          <Doughnut data={data} type={'doughnut'}/>
        </Col>
        <Col lg={5} className='mt-5 ml-10  mr-10 mx-auto float-none shadow p-3 mb-5 bg-body rounded'>
          <InputGroup className="mb-2">
            <InputGroup.Text>Filter</InputGroup.Text>
            <FormControl type='date' id="inlineFormInputGroup"
                         onChange={e => this.setState({filter: e.target.value})}/>
          </InputGroup>
          <Table striped bordered hover variant="dark">
            <thead>
            <tr>
              <th>Choose</th>
              <th>Metering Data</th>
            </tr>
            </thead>
            <tbody>
            {this.apis.map((api, k) => (<tr>
              <td>
                <Form.Check inline type='checkbox'
                            onChange={(event) => this.handle_checkbox_change(event, api)}/>
              </td>
              <td>{api}</td>
            </tr>))}
            </tbody>
          </Table>
          <Button onClick={this.download}>Download</Button>

        </Col>
        <br/>
        <Col lg={5} className='mt-5 ml-10  mr-10 mx-auto float-none shadow p-3 mb-5 bg-body rounded'>
          <Row>
            <Button onClick={this.download_emails}>Download Emails</Button>
          </Row>
          <Row>
            <Button onClick={this.download_emails_no_consent}>Download Emails No Consent</Button>
          </Row>
          <Row>
            <Button onClick={this.download_mpans}>Download Mpans</Button>
          </Row>
          <Row>
            <Button onClick={this.download_potential_mpans}>Download Potential Mpans</Button>
          </Row>
          <Row>
            <Button onClick={this.download_redeem_history_emails}>Download Redeem History
              Emails</Button>
          </Row>
        </Col>
        <Col lg={5} className='mt-5 ml-10  mr-10 mx-auto float-none shadow p-3 mb-5 bg-body rounded'>
          <Row>
            <Form.Control className='mb-3' size="lg" type="text" placeholder="Points"
                          onChange={value => this.setState({value: value.target.value})}/>
            <Button onClick={this.download_email_points}>Download email points</Button>
          </Row>
        </Col>
        <hr></hr>
        <Row>
          <Col className='mt-5 ml-10  mr-10 mx-auto float-none shadow p-3 mb-5 bg-body rounded'>
          <Form.Group controlId="formFile" className="m-3">
            <Form.Label>Upload mpans csv</Form.Label>
            <Form.Control className="m-3" size='lg' type="file" onChange={e => this.upload_csv(e.target.files[0])}/>
            <Form.Select className="m-3" onChange={v => this.setState({target: v.target.value})}>
              <option>Target (Only applicable to notifications)</option>
              <option value='user'>User</option>
              <option value='guest'>Guest</option>
            </Form.Select>
          <Form.Control className='m-3' size="lg" type="text" placeholder="Title"
            onChange={value => this.setState({title: value.target.value})}/>
          <Form.Control className='m-3' size="lg" as="textarea" placeholder="Body"
            onChange={value => this.setState({body: value.target.value})}/>
          <Form.Control className='m-3' size="lg" type="datetime-local" placeholder="time"
            onChange={value => this.setState({time: value.target.value})}/>
          <Form.Select className="m-3" onChange={v => this.setState({server: v.target.value})}>
            <option>Server (Only applicable to emails)</option>
            <option value='production'>Production</option>
            <option value='staging'>Staging</option>
            <option value='development'>Development</option>
          </Form.Select>
            <Form.Control className='m-3' size="lg" type="datetime-local" placeholder="time"
              onChange={value => this.setState({time_from: value.target.value})}/>
            <Form.Control className='m-3' size="lg" type="datetime-local" placeholder="time"
              onChange={value => this.setState({time_to: value.target.value})}/>
          <Button className="m-3" onClick={this.send_attend_grid_events_email}>Send Attend Grid Events Email</Button>
          <Button className="m-3" onClick={this.schedule_notification}>Schedule Notification</Button>
          </Form.Group>
          
          <ToastContainer/>
          </Col>
        </Row>
        <div style={{ padding: '20px 50px 0px 50px'}}>
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>#</th>
                <th>Mpan</th>
              </tr>
            </thead>
            <tbody>
              {this.state.mpans.map((v, k) => {
                return (<tr>
                  <td>{k}</td>
                  <td>{v}</td>
                </tr>)
              })}
            </tbody>
          </Table>
        </div>
      </Row>
    </div>
    );
  }
}

export default withRouter(Dashboard);
