import react from 'react';
import {
  Button,
  Col,
  Container, Dropdown,
  Modal, Pagination,
  Row, Table
} from 'react-bootstrap';
import axios from 'axios';
import {withRouter} from 'react-router-dom';
import React from 'react';
import {GET_INSTRUCTIONS, GET_INSTRUCTION, DELETE_INSTRUCTION} from '../../../env';
import Auth from '../../../Auth';
import NewCreation from './NewCreation';
import * as fileDownload from 'js-file-download';


class Instructions extends react.Component {
  constructor(props) {
    super(props);
    this.state = {
      count: 0,
      instructions: [],
      skip: 0,
      limit: 0,
      page_index: 0,
      gap: 10,
      show_create: false,
      show_confirm_deletion: false,
      show_details: false,
      instruction: null
    }

    this.get_instructions = this.get_instructions.bind(this)
    this.show_details = this.show_details.bind(this)
    this.hide_details = this.hide_details.bind(this)
    this.download_mpans = this.download_mpans.bind(this)
  }

  componentDidMount() {
    this.state.limit = this.state.gap;
    this.get_instructions();
  }

  get_instructions() {
    const url = `${GET_INSTRUCTIONS}?skip=${this.state.skip}&limit=${this.state.limit}`;
    axios.get(url, { headers: { Authorization: `bearer ${localStorage.getItem('token')}`, } }).then(
      res => {
        this.setState({ count: res.data.count })
        this.setState({ instructions: res.data.instructions })
      }
    ).catch((error) => {
//      if (error.response.status === 403) {
//        Auth.force_logout(this.props.history.push)
//      }
    })
  }

  get_instruction(id) {
    const url = `${GET_INSTRUCTION}/${id}`;
    axios.get(url, { headers: { Authorization: `bearer ${localStorage.getItem('token')}`, } }).then(
      res => {
        this.setState({ instruction: res.data })
      }
      ).catch((error) => {
        //      if (error.response.status === 403) {
        //        Auth.force_logout(this.props.history.push)
        //      }
      })
  }

  delete_instruction(id) {
    const url = `${DELETE_INSTRUCTION}/${id}`;
    axios.delete(url, { headers: { Authorization: `bearer ${localStorage.getItem('token')}`, } }).then(
      res => {
        this.setState({ show_confirm_deletion: false })
        this.get_instructions();
      }
    ).catch((error) => {
//      if (error.response.status === 403) {
//        Auth.force_logout(this.props.history.push)
//      }
    })
  }

  parse_datetime(value) {
    if (value) {
      const [date, time] = value.split('T');
      const [h, m] = time.split(':');
      return `${date} At ${h}:${m}`;
    }
  }

  get_pagination() {
    const pages = []
    let page_index = 1;
    for (let i = 0; i < this.state.count; i += this.state.gap) {
      if (this.state.page_index === i) {
        pages.push(<Pagination.Item active onClick={() => this.get_page(i)}>{page_index}</Pagination.Item>)
      } else {
        pages.push(<Pagination.Item onClick={() => this.get_page(i)}>{page_index}</Pagination.Item>)
      }
      ++page_index;
    }
    return pages;
  }

  get_page(skip) {
    this.setState({
      skip: skip,
      limit: skip + this.state.gap,
      page_index: skip,
    }, () => {
      this.get_instructions();
    })
  }

  download_mpans() {
    let csv = 'mpan\n';
    for (const mpan of this.state.instruction.mpans) {
      csv += `${mpan}\n`;
    }
    fileDownload(csv, 'event_mpans.csv');
  }

  show_create = () => this.setState({show_create: true});
  hide_create = () => this.setState({show_create: false});

  show_details  = (id) => {
    this.get_instruction(id)
    this.setState({show_details: true })
  }
  hide_details  = () => {
    this.setState({show_details: false, instruction: null});
  }

  render() {
    return (
      <Container>
        <Button className='mb-2, mt-5' variant='primary' onClick={this.show_create}>
          Create
        </Button>
        <Row className='mt-5'>
          <Col>
            <Table striped bordered hover variant='dark'>
              <thead>
                <tr>
                  <th>Instructor</th>
                  <th>Units</th>
                  <th>Demand</th>
                  <th>Start</th>
                  <th>End</th>
                  <th>Created At</th>
                  <th>Modified At</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {this.state.instructions.map((instruction, index) => (
                  <tr key={index}>
                    <td>{instruction.instructor.name}</td>
                    <td>
                      <Dropdown>
                        <Dropdown.Toggle variant="success" id="dropdown-basic">
                          Units'
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                          {instruction.units.map((unit, index) => (
                            <Dropdown.Item>{unit.name}</Dropdown.Item>
                            ))}
                        </Dropdown.Menu>
                      </Dropdown>
                    </td>
                    <td>{instruction.demand}</td>
                    <td>{this.parse_datetime(instruction.start)}</td>
                    <td>{this.parse_datetime(instruction.end)}</td>
                    <td>{this.parse_datetime(instruction.createdAt)}</td>
                    <td>{this.parse_datetime(instruction.updatedAt)}</td>
                    <td>
                      <Button className='mb-2' variant='primary'
                        onClick={() => this.show_details(instruction._id) }>Details</Button>
                      <Button variant='danger' onClick={() => this.setState({show_confirm_deletion: true, instruction_id: instruction._id})}>Delete</Button>
                    </td>
                  </tr>
                  )
                  )}
              </tbody>
            </Table>
          </Col>
          <Pagination>
            {this.get_pagination()}
          </Pagination>
          <Modal size="sm" show={this.state.show_details}
            onHide={this.hide_details}>
            <Modal.Header closeButton>
              <Modal.Title>
                Details
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              {this.state.instruction ?
              <div>
                <h6>{this.state.instruction.event.status === 0 ? 'Event has not started yet'
          : this.state.instruction.event.status === 1 ? 'Event in progress': 'Event ended'}</h6>
                <Button variant='success' style={{ 'border-radius': 0 }} onClick={this.download_mpans}>Download mpans</Button>
              </div>
              : <h6>Loading</h6>}

            </Modal.Body>
          </Modal>
          <Modal size="sm" show={this.state.show_confirm_deletion}
                 onHide={() => this.setState({ show_confirm_deletion: false })}
                 aria-labelledby="example-modal-sizes-title-lg">
            <Modal.Header closeButton>
              <Modal.Title id="example-modal-sizes-title-lg">
                Confirm Deletion
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <h6>Are you sure you want to delete this instruction.</h6>
              <h6>This action CANNOT be reversed.</h6>
            </Modal.Body>
            <Button variant='danger' style={{ 'border-radius': 0 }} onClick={() => this.delete_instruction(this.state.instruction_id)}>Delete</Button>
          </Modal>
        </Row>
        <NewCreation show={this.state.show_create} hide={this.hide_create}/>
      </Container>
    )
  }
}

export default withRouter(Instructions);
