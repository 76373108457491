import {Button, Dropdown, FloatingLabel, Form} from 'react-bootstrap';
import react from 'react';
import {withRouter} from 'react-router-dom';
import React from 'react';
import axios from 'axios';
import {CREATE_INSTRUCTION, GET_INSTRUCTORS, GET_UNITS} from '../../../env';

class NewInstruction extends react.Component {
  constructor(props) {
    super(props);
    this.state = {
      instructors: [],
      instructor: null,
      units: [],
      chosen_units: [],
      demand: null,
      start: 0,
      end: 0,
    }

    this.start = this.start.bind(this)
    this.end = this.end.bind(this)
    this.create = this.create.bind(this)
    this.set_demand = this.set_demand.bind(this)
  }

  componentDidMount() {
    this.get_instructors();
    this.get_units();
  }

  get_instructors() {
    axios.get(GET_INSTRUCTORS,
      {
        headers: {
          Authorization: `bearer ${localStorage.getItem('token')}`
        }
      }
    ).then(r => this.setState({ instructors: r.data })).catch(_ => null)
  }

  get_units() {
    axios.get(GET_UNITS,
      {
        headers: {
          Authorization: `bearer ${localStorage.getItem('token')}`
        }
      }
    ).then(r => this.setState({ units: r.data })).catch(_ => null)
  }



  start = (value) => this.setState({ start: new Date(value).getTime() })

  end = (value) => this.setState({ end: new Date(value).getTime() })

  create() {

    if (this.state.instructor
      && this.state.chosen_units.length > 0
      && this.state.start > 0
      && this.state.end > 0) {
      axios.post(CREATE_INSTRUCTION,
        {
          instructor_id: this.state.instructor._id,
          units: this.state.chosen_units,
          demand: this.state.demand,
          start: this.state.start,
          end: this.state.end,
        },
        {
          headers: {
            Authorization: `bearer ${localStorage.getItem('token')}`
          }
        }
      ).then(_ => {
        this.props.hide()
      }).catch(_ => null)
    }
  }

  select_instructor = (instructor) => this.setState({ instructor })


  add_unit(unit) {
    for (let chosen_unit of this.state.chosen_units) if (unit._id === chosen_unit._id) return
    this.setState({ chosen_units: [...this.state.chosen_units, unit] })
  }

  remove_unit(id) {
    const temp = []
    for (let unit of this.state.chosen_units) {
      if (unit._id !== id) {
        temp.push(unit)
      }
    }
    this.setState({ chosen_units: [...temp] })
  }

  set_demand = (value) => this.setState({ demand: value })


  render() {
    return (
      <Form>
        <h6>Choose instructor</h6>
        <Dropdown className='mb-4'>
          <Dropdown.Toggle variant="success" id="dropdown-basic">
            Instructors
          </Dropdown.Toggle>
          <Dropdown.Menu>
            {this.state.instructors.map((instructor, index) => (
              <Dropdown.Item onClick={() => this.select_instructor(instructor)}>{instructor.name}</Dropdown.Item>
            ))}
          </Dropdown.Menu>
        </Dropdown>
        <Button variant='info btn-block' className='mb-3' size='sm'>
          {this.state.instructor ? this.state.instructor.name : 'No instructor selected'}
        </Button>
        <Dropdown className='mb-4'>
          <Dropdown.Toggle variant="success" id="dropdown-basic">
            Demand
          </Dropdown.Toggle>
          <Dropdown.Menu>
            <Dropdown.Item onClick={() => this.set_demand('turn_up')}>turn_up</Dropdown.Item>
            <Dropdown.Item onClick={() => this.set_demand('turn_down')}>turn_down</Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
        <Button variant='info btn-block' className='mb-3' size='sm'>
          {this.state.demand ? this.state.demand : 'No Demand selected'}
        </Button>
        <h6>Choose units</h6>
        <Dropdown className='mb-4'>
          <Dropdown.Toggle variant="success" id="dropdown-basic">
            Units
          </Dropdown.Toggle>
          <Dropdown.Menu>
            {this.state.units.map((unit, index) => (
              <Dropdown.Item onClick={() => this.add_unit(unit)}>{unit.name}</Dropdown.Item>
            ))}
          </Dropdown.Menu>
        </Dropdown>

        {this.state.chosen_units.map((unit, index) => (
          <Button key={unit._id} variant='info btn-block' className='mb-3 m-2' size='sm'
                  onClick={() => this.remove_unit(unit._id)}>{unit.name}</Button>
        ))}

        <FloatingLabel controlId="floatingInput" label="Start" className="mb-3">
          <Form.Control type="datetime-local" placeholder="Start"
                        onChange={value => this.start(value.target.value)}/>
        </FloatingLabel>
        <FloatingLabel controlId="floatingInput" label="End" className="mb-3">
          <Form.Control type="datetime-local" placeholder="End"
                        onChange={value => this.end(value.target.value)}/>
        </FloatingLabel>
        <Button variant='primary btn-block' className='mb-3' size='lg' onClick={this.create}>Create</Button>
      </Form>
    );
  }
}

export default withRouter(NewInstruction);
