import react from 'react';


import './calendar.css';
import {

    Container,
} from 'react-bootstrap';


import axios from 'axios';
import { withRouter } from 'react-router-dom';
import React from 'react';
import { POST_UPDATED_EXPORT_MPANS_CSV } from '../../../env';
import * as csv from "csvtojson";
import * as fileDownload from 'js-file-download';

class ExportMpanCSV extends react.Component {
    constructor(props) {
        super(props);
        this.state = {
            date: "",
            mpans: [],
            files: [],
        }
    }

    componentDidMount() {

    }



    uploadCSV(event) {
        const file = event.target.files[0];
        if (!file) { alert("Please select a file"); return }
        const reader = new FileReader();
        reader.onload = async (e) => {
            const text = (e.target.result);
            const csvData = await csv().fromString(text);
            const response = await axios.post(POST_UPDATED_EXPORT_MPANS_CSV, { csvData }, {
                headers: {
                    Authorization: `bearer ${localStorage.getItem('token')}`
                }
            })
            fileDownload(response.data, 'filename.csv');
            return;
        };
        reader.readAsText(file);

    }











    render() {
        return (
            <Container>
                <br />
                <div>
                    <h1>Upload The CSV files and Thats it </h1><br />
                    <input type="file" id="myFile" name="filename" onChange={this.uploadCSV} />


                </div>

            </Container>
        )
    }
}

export default withRouter(ExportMpanCSV);
