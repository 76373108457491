import {Modal, Spinner, Tab, Tabs} from 'react-bootstrap';
import react from 'react';
import Zappi from './Zappi';
import Eddi from './Eddi';
import axios from 'axios';
import {GET_MYENERGI_CUSTOMERS} from '../../../../env';
import Auth from '../../../../Auth';
import {withRouter} from 'react-router-dom';

class CustomerInformation extends react.Component
{
  constructor(props)
  {
    super(props);
    this.state = {
      zappi: {},
      eddi: {},
      loading: true,
      show: true
    }
    this.get_myenergi_customer_details = this.get_myenergi_customer_details.bind(this)
    this.setState({ show: this.props.show })
  }

  componentWillMount()
  {
    this.get_myenergi_customer_details()
  }

  get_myenergi_customer_details()
  {
    axios.get(`${GET_MYENERGI_CUSTOMERS}/${this.props.customer._id}`, {
      headers: {
        Authorization: `bearer ${localStorage.getItem('token')}`
      }
    }).then(
      res =>
      {
        this.setState({ zappi: res.data.zappi })
        this.setState({ eddi: res.data.eddi })
        this.setState({ loading: false })
      }
    ).catch((error) =>
    {
      if (error.response.status === 403) {
        Auth.force_logout(this.props.history.push)
      }
    })
  }

  render()
  {
    return (
      <Modal size="xl" show={this.state.show} onHide={this.props.hide}>
        <Modal.Header closeButton>
          <Modal.Title>
            {this.props.customer.email}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Tabs defaultActiveKey="zappi" transition={false} className="mb-3">
            <Tab eventKey="zappi" title="Zappi">
              {this.state.loading ?
                <Spinner animation="border" role="status">
                  <span className="visually-hidden">Loading...</span>
                </Spinner> : <Zappi zappi={this.state.zappi}/>}
            </Tab>
            <Tab eventKey="eddi" title="Eddi">
              {this.state.loading ?
                <Spinner animation="border" role="status">
                <span className="visually-hidden">Loading...</span>
                </Spinner> : <Eddi eddi={this.state.eddi}/>}
            </Tab>
          </Tabs>
        </Modal.Body>
      </Modal>
    );
  }
}

export default withRouter(CustomerInformation);
