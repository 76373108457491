import {Col, Form, Row} from 'react-bootstrap';
import {buildStyles, CircularProgressbar} from 'react-circular-progressbar';
import react from 'react';
import React from 'react';
import 'react-circular-progressbar/dist/styles.css';


class Devices extends react.Component
{
  constructor(props)
  {
    super(props);
    this.pst = {
      A: 'EV Disconnected',
      B1: 'EV Connected',
      B2: 'Waiting for EV',
      C1: 'Charging',
      C2: 'Charging Max Power',
      F: 'Fault/Restart'
    }
    this.sta = {
      0: 'Starting',
      1: 'Waiting for export',
      2: 'DSR',
      3: 'Diverting',
      4: 'Boosting',
      5: 'Hot',
      6: 'Stopped'
    }
    this.zmo = {
      1: 'Fast',
      2: 'ECO',
      3: 'ECO+',
      4: 'Stop'
    }
    this.handle_device = this.handle_device.bind(this)
  }


  handle_device(device)
  {
    const { device_type } = device
    switch (device_type)
    {
      case 'zappi':
        return (
          <>
            <Row>
              <Col>
                <h5>Zappi</h5>
              </Col>
              <Col>
                <h5>Pilot State: {this.pst[device.pst]}</h5>
              </Col>
              <Col>
                <h5>Mode: {this.zmo[device.zmo]}</h5>
              </Col>
            </Row>
            <hr/>
          </>
        )
      case 'eddi':
        return (
          <>
            <Row>
              <Col>
                <h5>Eddi: {device.device_id}</h5>
              </Col>
              <Col>
                <h5>Status: {this.sta[device.sta]}</h5>
              </Col>
            </Row>
            <hr/>
          </>
        )
      case 'harvi':
        return (
          <>
            <Row>
              <Col>
                <h5>Harvi</h5>
              </Col>
            </Row>
            <hr/>
          </>
        )
      case 'tado':
        return (
          <>
            <Row>
              <Col>
                <h5>Tado</h5>
              </Col>
              <Col>
                <Form.Check disabled checked={device.online} type='switch' id='mode' label='Online'/>
              </Col>
            </Row>
            <hr/>
          </>
        )
      case 'tplink':
        return (
          <>
            <Row>
              <Col>
                <h5>Tplink: {device.name}</h5>
              </Col>
              <Col>
                <Form.Check disabled checked={device.is_reachable} type='switch' id='mode' label='Reachable'/>
              </Col>
              <Col>
                <Form.Check disabled checked={device.state} type='switch' id='mode' label='Online'/>
              </Col>
            </Row>
            <hr/>
          </>
        )
      case 'tesla':
        const battery_level = device.charge_state ? device.charge_state.battery_level : 0
        return (
          <>
            <Row className='mb-3'>
              <Col>
                <h5>Tesla: {device.display_name}</h5>
              </Col>
              <Col>
                <Form.Check disabled checked={device.state} type='switch' id='mode' label='Online'/>
              </Col>
              <Col>
                <div style={{ width: 75, height: 75 }}>
                  <CircularProgressbar
                    value={battery_level}
                    text={`${battery_level}%`}
                    background
                    backgroundPadding={6}
                    styles={buildStyles({
                      backgroundColor: "#3e98c7",
                      textColor: "#fff",
                      pathColor: "#fff",
                      trailColor: "transparent"
                    })}
                  />
                </div>
              </Col>
            </Row>
            <hr/>
          </>
        )
      default:
        return
    }
  }

  render()
  {
    return (this.props.devices.map(device => this.handle_device(device)));
  }
}

export default Devices;
