import React from 'react';
import {Button, Col, Container, Form, Row} from 'react-bootstrap';
import './Login.css'
import login_icon from './user.png'
import axios from 'axios'
import {RESET_PASSWORD} from '../../env';
import {withRouter} from 'react-router-dom';
import react from 'react';
import Auth from '../../Auth';

class ResetPassword extends react.Component {
  constructor(props) {
    super(props);
    Auth.authorize(this.props.history)
    this.state = {
      password: ''
    };
    this.reset_password = this.reset_password.bind(this);
    localStorage.setItem('token', '')
    localStorage.setItem('is_authenticated', 'false')
  }

  reset_password() {
    // get state from url
    const state = this.props.location.search.split('=')[1]

    if (this.state.password === '') {
      alert('Please enter password')
      return
    }
    axios.post(RESET_PASSWORD, {state, password: this.state.password}).then(res => {
      this.props.history.push('/login');
      alert('Password reset successfully');
    }).catch(error => {
      alert('Was not able to reset password');
    })
  }

  render() {
    return (
      <Container className='mt-5'>
        <Row>
          <Col lg={4} md={6} sm={12} className='text-center mt-5 p-3'>
            <img className='icon-img' src={login_icon} alt='icon'/>
            <h3>Admin Reset Password</h3>
            <Form>
              <Form.Group className='mb-3' controlId='formGroupPassword'>
                <Form.Control type='password' placeholder='Password'
                              onChange={value => this.setState({password: value.target.value})}/>
              </Form.Group>

              <Button onClick={this.reset_password}>
                Reset Password
              </Button>
            </Form>
          </Col>
        </Row>
      </Container>
    );
  }
}

export default withRouter(ResetPassword);
