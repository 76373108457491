import React, {useEffect, useState} from "react";
import axios from "axios";
import {
  CREATE_ROLE,
  DELETE_ROLE,
  GET_PERMISSIONS,
  GET_ROLES, UPDATE_ROLE,
} from "../../env";
import {Button, Container, Dropdown, Form, Modal, Table} from "react-bootstrap";

export default function Roles() {
  const [permissions, setPermissions] = useState([])
  const [roles, setRoles] = useState([])
  const [role, setRole] = useState({
    _id: '',
    name: '',
    description: '',
    permissions: []
  })
  const [showConfirmDeletion, setShowConfirmDeletion] = useState(false)
  const [showCreateRole, setShowCreateRole] = useState(false)
  const [showEditRole, setShowEditRole] = useState(false)

  useEffect(() => {
    getRoles()
    getPermissions()
  }, []);
  const getPermissions = () => {
    const token = `bearer ${localStorage.getItem('token')}`
    axios.get(GET_PERMISSIONS,
      {headers: {Authorization: token}})
      .then(res => {
        setPermissions(res.data)
      })
  }
  const createRole = () => {
    const data = {
      name: role.name,
      description: role.description,
      permissions: role.permissions.map(perm => perm._id)
    }
    const token = `bearer ${localStorage.getItem('token')}`
    axios.post(CREATE_ROLE, data,
      {headers: {Authorization: token}})
      .then(_ => {
          setRole({
            _id: '',
            name: '',
            description: '',
            permissions: []
          })
          setShowCreateRole(false)
          getRoles()
        }
      )
  }
  const getRoles = () => {
    const token = `bearer ${localStorage.getItem('token')}`
    axios.get(GET_ROLES, {headers: {Authorization: token}})
      .then(res => {
        setRoles(res.data)
      })
  }

  const editRole = (role) => {
    setRole(role)
    setShowEditRole(true)
  }
  const updateRole = () => {
    const data = {
      name: role.name,
      description: role.description,
      permissions: role.permissions.map(perm => perm._id)
    }
    const token = `bearer ${localStorage.getItem('token')}`
    axios.put(`${UPDATE_ROLE}/${role._id}`, data,
      {headers: {Authorization: token}})
      .then(_ => {
        setRole({
          _id: '',
          name: '',
          description: '',
          permissions: []
        })
        setShowEditRole(false)
        getRoles()
      })
  }
  const confirmDeletion = (role) => {
    setRole(role)
    setShowConfirmDeletion(true)
  }
  const deleteRole = () => {
    const token = `bearer ${localStorage.getItem('token')}`
    axios.delete(`${DELETE_ROLE}/${role._id}`,
      {
        headers: {Authorization: token}
      }).then(_ => {
      setRole({})
      setShowConfirmDeletion(false)
      getRoles()
    })
  }

  const addPermission = (permission) => {
    if (role.permissions.includes(permission)) {
      return
    }
    setRole({...role, permissions: [...role.permissions, permission]})
  }
  const removePermission = (permission) => {
    setRole({...role, permissions: role.permissions.filter(perm => perm._id !== permission._id)})
  }
  return (
    <Container>
      <Button onClick={() => setShowCreateRole(true)}>Create Role</Button>
      <Table striped bordered hover variant='dark'>
        <thead>
        <tr>
          <th>ID</th>
          <th>Role Name</th>
          <th>Description</th>
          <th>Permissions</th>
          <th>Actions</th>
        </tr>
        </thead>
        <tbody>
        {roles.map((role, index) => {
          return (
            <tr key={index}>
              <td>{role._id}</td>
              <td>{role.name}</td>
              <td>{role.description}</td>
              <td>
                <ul>
                  {role.permissions.map((permission, index) => {
                    return (
                      <li key={index}>{permission.name}</li>
                    )
                  })}
                </ul>
              </td>
              <td>
                <Button className='mb-2' variant='primary'
                        onClick={() => editRole(role)}>Edit</Button>
                <Button variant='danger' onClick={() => confirmDeletion(role)}>Delete</Button>
              </td>
            </tr>
          )
        })}
        </tbody>
      </Table>
      <Modal size="sm" show={showCreateRole}
             onHide={() => setShowCreateRole(false)}>
        <Modal.Header closeButton>
          <Modal.Title>
            Create Role
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className='mb-3' controlId='formGroupEmail'>
              <Form.Control type='text' placeholder='Role Name'
                            onChange={value => setRole({...role, name: value.target.value})}/>
              <br/>
              <Form.Control type='text' placeholder='Description'
                            onChange={value => setRole({...role, description: value.target.value})}/>
              <br/>
              <Dropdown className='mb-4'>
                <Dropdown.Toggle variant="success" id="dropdown-basic">
                  Permissions
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  {permissions.map((perm, _) => (
                    <Dropdown.Item onClick={() => addPermission(perm)}>{perm.name}</Dropdown.Item>
                  ))}
                </Dropdown.Menu>
              </Dropdown>
            </Form.Group>
          </Form>
        </Modal.Body>
        <ul>
          {role.permissions.map((perm, index) => {
            return (
              <li key={index} onClick={() => removePermission(perm)}>{perm.name}</li>
            )
          })}
        </ul>
        <Button variant='primary btn-block' style={{'border-radius': 0}} onClick={createRole}>Create</Button>
      </Modal>

      <Modal size="sm" show={showEditRole}
             onHide={() => setShowEditRole(false)}>
        <Modal.Header closeButton>
          <Modal.Title>
            Edit Role <mark>{role.name}</mark>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className='mb-3' controlId='formGroupEmail'>
              <Form.Control type='text' placeholder='Role Name' defaultValue={role.name}
                            onChange={value => setRole({...role, name: value.target.value})}/>
              <br/>
              <Form.Control type='text' placeholder='Description' defaultValue={role.description}
                            onChange={value => setRole({...role, description: value.target.value})}/>
            </Form.Group>
            <Dropdown className='mb-4'>
              <Dropdown.Toggle variant="success" id="dropdown-basic">
                Permissions
              </Dropdown.Toggle>
              <Dropdown.Menu>
                {permissions.map((perm, _) => (
                  <Dropdown.Item onClick={() => addPermission(perm)}>{perm.name}</Dropdown.Item>
                ))}
              </Dropdown.Menu>
            </Dropdown>
          </Form>
          <ul>
            {role.permissions.map((perm, index) => {
              return (
                <li key={index} onClick={() => removePermission(perm)}>{perm.name}</li>
              )
            })}
          </ul>
        </Modal.Body>
        <Button variant='primary btn-block' style={{'border-radius': 0}} onClick={updateRole}>Edit</Button>
      </Modal>

      <Modal size="sm" show={showConfirmDeletion}
             onHide={() => setShowConfirmDeletion(false)}
             aria-labelledby="example-modal-sizes-title-lg">
        <Modal.Header closeButton>
          <Modal.Title id="example-modal-sizes-title-lg">
            Confirm Deletion
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h6>Are you sure you want to delete this role
            <mark>{role.name}</mark>?
          </h6>
          <h6>This action CANNOT be reversed.</h6>
        </Modal.Body>
        <Button variant='danger' style={{'border-radius': 0}} onClick={deleteRole}>Delete</Button>
      </Modal>
    </Container>
  )

}
