import {Route, withRouter} from 'react-router-dom';
import react from 'react';
import Instructions from './instructions/Instructions';
import React from 'react';
import PaypalRedeemRequests from './paypal/PaypalRedeemRequests';
import PaypalRedeemRequestsHistory from './paypal/PaypalRedeemRequestsHistory';
import MpanAddressVerify from './mpan/MpanAddressVerify';
import UserTrends from './mpan/UserTrends';
import MeteringByDate from './mpan/MeteringByDate';
import ExportMpanCSV from './mpan/ExportMpanCSV';
import N3rgy from './mpan/n3rgy';


class AdminRoutes extends react.Component
{
  render()
  {
    return (
      <>
        <Route path='/admin/instructions'>
          <Instructions/>
        </Route>
        <Route exact path='/admin/mpan-address-verify'>
          <MpanAddressVerify/>
        </Route>
        <Route exact path='/admin/user-metrics'>
          <UserTrends/>
        </Route>

        <Route exact path='/admin/mpan/metering-by-date'>
          <MeteringByDate/>
        </Route>
        <Route exact path='/admin/mpan/export-mpans'>
          <ExportMpanCSV/>
        </Route>
        <Route exact path='/admin/mpan/n3rgy'>
          <N3rgy/>
        </Route>
        <Route exact path='/admin/paypal/redeem-requests'>
          <PaypalRedeemRequests/>
        </Route>
        <Route exact path='/admin/paypal/redeem-requests/history'>
          <PaypalRedeemRequestsHistory/>
        </Route>

      </>
    );
  }
}

export default withRouter(AdminRoutes);
