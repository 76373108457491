import {Col, Row} from 'react-bootstrap';
import react from 'react';
import {Chart} from 'react-chartjs-2';
import ReactDOM from 'react-dom';

class Devices extends react.Component
{
  chart = null;

  constructor(props)
  {
    super(props);
    this.pst = {
      A: 'EV Disconnected',
      B1: 'EV Connected',
      B2: 'Waiting for EV',
      C1: 'Charging',
      C2: 'Charging Max Power',
      F: 'Fault/Restart'
    }
    this.zmo = {
      1: 'Fast',
      2: 'ECO',
      3: 'ECO+',
      4: 'Stop'
    }
  }


  componentDidMount()
  {
    this.configure_chart();
  }

  configure_chart()
  {
    const metering = []
    const upwards = []
    const downwards = []
    const date = []
    const len = this.props.zappi._metering.count
    let i = len >= 48 ? len - 48 : 0;
    for (; i < len; i++)
    {
      const time = this.props.zappi._metering.metering[i].creation_date
      metering.push(this.props.zappi._metering.metering[i].energy)
      date.push(time.split('T')[1].slice(0, 5))

      if (this.props.zappi._metering.metering[i].energy < 1400)
      {
        upwards.push(0)
        downwards.push(0)
      } else if (this.props.zappi._metering.metering[i].energy >= 1400 && this.props.zappi._metering.metering[i].energy <= 1700)
      {
        upwards.push(0)
        downwards.push(5500)
      } else if (this.props.zappi._metering.metering[i].energy > 1700 && this.props.zappi._metering.metering[i].energy <= 7500)
      {
        upwards.push(this.props.zappi._metering.metering[i].energy - 1500)
        downwards.push(7500 - this.props.zappi._metering.metering[i].energy)
      } else
      {
        upwards.push(this.props.zappi._metering.metering[i].energy - 1500)
        downwards.push(0)
      }
    }


    const chart_canvas = ReactDOM.findDOMNode(this.chart);

    new Chart(chart_canvas, {
        type: "bar",
        data: {
          labels: date,
          datasets: [
            {
              label: "Metering (WATTS)",
              data: metering,
              type: "line",
              borderColor: 'rgb(54,75,75)',
              pointStyle: "circle",
            },
            {
              label: "Downwards",
              data: downwards,
              type: "bar",
              backgroundColor: "#DE924B"
            },
            {
              label: "Upwards",
              data: upwards,
              type: "bar",
              backgroundColor: "#71A4F1"
            },
          ],
        },
        options: {
          plugins: {
            title: {
              display: true,
              text: 'Metering'
            },
          },
          responsive: true,
          scales: {
            x: {
              stacked: true,
            },
            y: {
              stacked: true
            }
          }
        }
      },
    );
  }

  render()
  {
    return (
      <Row>
        <Col>
          <h5>Zappi: {this.props.zappi.device_id}</h5>
        </Col>
        <Col>
          <h5>Pilot State: {this.pst[this.props.zappi.pst]}</h5>
        </Col>
        <Col>
          <h5>Mode: {this.zmo[this.props.zappi.zmo]}</h5>
        </Col>
        <h5>Reward: £{this.props.zappi.reward.toFixed(2)}</h5>
        <h5>Points earned: {this.props.zappi.points.toFixed(2)}</h5>
        <canvas ref={chart => this.chart = chart}/>
      </Row>
    );
  }
}

export default Devices;
