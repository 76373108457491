// Metering and Baseline Configuration
import react from 'react';


import './calendar.css';
import {
    Form,
    Container,
    Button,Table
} from 'react-bootstrap';


import axios from 'axios';
import { withRouter } from 'react-router-dom';
import React from 'react';
import { GET_N3RGY_METERING, GET_ADMIN_UPLOADS, GET_N3RGY_BASELINE, GET_EVENTS, GET_FILE_URL } from '../../../env';

import * as fileDownload from 'js-file-download';


class N3rgy extends react.Component {
    constructor(props) {
        super(props);
        this.state = {
            events: [],
            files: [],
            selectedEvent: null

        }
    }

    componentDidMount() {
        this.getEvents()
    }

    async getEvents() {
        const response = await axios.get(GET_EVENTS, {
            headers: {
                Authorization: `bearer ${localStorage.getItem('token')}`
            }
        });
        this.setState({ events: response.data });
    }

    getMetering() {
        if(!this.state.selectedEvent){
            alert('Please select an event');
            return;
        }
        axios.get(GET_N3RGY_METERING, {
            headers: {
                Authorization: `bearer ${localStorage.getItem('token')}`
            },
            params: {
                event: this.state.selectedEvent
            }
        }).then(response => {
            alert('Metering Data in Progress');
        }).catch(error => {
            alert("Error: "+error.response.data)
        })
    }
    async getBaseline() {
        if(!this.state.selectedEvent){
            alert('Please select an event');
            return;
        }
        axios.get(GET_N3RGY_BASELINE, {
            headers: {
                Authorization: `bearer ${localStorage.getItem('token')}`
            },
            params: {
                event: this.state.selectedEvent
            }
        }).then(response => {
            alert('Baseline Data in Progress');
        }).catch(error => {
            alert("Error: "+error.response.data);
        })
    }
    async downloadFiles(folder, filename) {
        const response = await axios.get(GET_FILE_URL, {

            headers: {
                Authorization: `bearer ${localStorage.getItem('token')}`
            },
            params: {
                folder,
                filename
            }

        });
        return response.data.url
    }

    async getAdminUploads(event) {
        if(!event && !this.state.selectedEvent){
            return;
        }
        const response = await axios.get(GET_ADMIN_UPLOADS, {

            headers: {
                Authorization: `bearer ${localStorage.getItem('token')}`
            },
            params: {
                event: event||this.state.selectedEvent
            }

        });
        

        this.setState({ files: response.data})
    }










    render() {
        return (
            <Container className='text-center shadow p-3 mb-5 bg-green rounded'>
                <br />
                <div>





                    <Form.Select aria-label="Default select example" onChange={(event) => {
                        this.setState({ selectedEvent: event.target.value });
                        this.getAdminUploads(event.target.value);
                    }}>
                        <option>Select Event Date</option>
                        {this.state.events.map((event, index) => {
                            return <option key={index} value={event._id}>{event.start}</option>
                        })
                        }
                    </Form.Select>



                </div>
                <div>
                    <h4>Download Files:</h4><br />
                    <Table striped bordered hover variant='dark'>
                    <thead>
                        <tr>
                            <th>Type</th>
                            <th>Event</th>
                            <th>File Name</th>
                            <th>Download</th>
                        </tr>
                        </thead>
                        <tbody>
                            {this.state.files.map((file, index) => {
                                console.log(file);
                                return <tr>
                                    <td>{file.type}</td>
                                    <td>{file.event}</td>
                                    <td>{file.name}</td>
                                    <td><button onClick={async () => {
                                        const url = await this.downloadFiles(file.user, file.name);
                                        window.open(url); 
                                    }} >Download</button></td>
                                </tr>
                            })
                            }
                       </tbody>
                    </Table>
                </div>
                <div>
                    <h3>Generate New:</h3><br />
                    <Button onClick={()=>this.getMetering()}  className='mx-auto' variant='primary btn-block' size='lg' >Generate Baseline</Button>
                    <br/>
                    <br/>
                    <Button className='mx-auto ' variant='primary btn-block' size='lg' onClick={()=>this.getBaseline()} >Generate Metering</Button>
                </div>
       
            </Container>
        )
    }
}

export default withRouter(N3rgy);
