import React from 'react';
import {Alert, Button, Col, Container, Form, Row} from 'react-bootstrap';
import './Login.css'
import login_icon from './user.png'
import axios from 'axios'
import {FORGOT_PASSWORD, LOGIN} from '../../env';
import {Link, withRouter} from 'react-router-dom';
import react from 'react';
import Auth from '../../Auth';

class Login extends react.Component {
  constructor(props) {
    super(props);
    Auth.authorize(this.props.history)
    this.state = {
      show: false,
      email: '',
      password: ''
    };
    this.login = this.login.bind(this);
    this.forgot_password = this.forgot_password.bind(this);
    localStorage.setItem('token', '')
    localStorage.setItem('is_authenticated', 'false')
  }

  login() {
    axios.post(LOGIN, { email: this.state.email, password: this.state.password }).then(res => {
      Auth.login(res.data.token, 'admin');
      this.props.history.push('/');
    }).catch(error => {
      this.state.show = true;
    })
  }

  forgot_password() {
    if (this.state.email === '') {
      alert('Please enter email')
      return
    }
    axios.post(FORGOT_PASSWORD, { email: this.state.email }).then(_ => {
      alert('Check your email for reset password link')
    }).catch(_ => {
      alert('Something went wrong')
    })
  }

  render() {
    return (
      <Container className='mt-5'>
        <h3 style={{"color":"#ee790b"}} className='text-center'>Orange Power</h3>
        <Row className='h-100  align-items-center justify-content-center '>
          <Col lg={4} md={6} sm={12} className='text-center mt-5 shadow p-3 mb-5 bg-white rounded'>
            <img className='icon-img' src={login_icon} alt='icon'/>
            <h3>Admin Login</h3>
            <br/>
            <br/>
            {this.state.show === true && <Alert variant='danger'>Wrong Email or password</Alert>}
            <Form>
              <Form.Group className='mb-3' controlId='formGroupEmail'>
                <Form.Control type='email' placeholder='Enter email'
                              onChange={value => this.setState({ email: value.target.value })}/>
              </Form.Group>
              <Form.Group className='mb-3' controlId='formGroupPassword'>
                <Form.Control type='password' placeholder='Password'
                              onChange={value => this.setState({ password: value.target.value })}/>
              </Form.Group>
              <br/>
              <Button variant='primary btn-block' className='mb-3 w-100' size='lg' onClick={this.login}>Login</Button>
              <br/>
              <br/>
              <Button  className='w-100' variant='primary btn-block' size='lg'>
                <Link style={{ color: 'white', 'text-decoration': 'none' }} to='/partners/login'>Partner Login</Link>
              </Button>
              <div className='text-left mt-3'>
                <Button className='w-100' onClick={this.forgot_password}>Reset Password</Button>
              </div>
            </Form>
          </Col>
        </Row>
      </Container>
    );
  }
}

export default withRouter(Login);
