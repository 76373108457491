import react from 'react';
import Calendar from 'react-calendar';
import moment from 'moment';

import './calendar.css';
import {
    Col,
    Container,
    Row,
} from 'react-bootstrap';


import axios from 'axios';
import { withRouter } from 'react-router-dom';
import React from 'react';
import { GET_METERING_BY_DATE, GET_FILE_URL, GET_ADMIN_UPLOADS } from '../../../env';



class MeteringByDate extends react.Component {
    constructor(props) {
        super(props);
        this.state = {
            date: "",
            mpans: [],
            files: [],
        }
    }

    componentDidMount() {
        this.getAdminUploads()
    }



    changeMpan(event) {
        
        let value = event.target.value;
        console.log(value)
        if (value.includes(',')) {
            value = value.split(',')
        }
        else {
            value = value.split(/\s+/gm)
        }
        value = value.filter((item) => item !== '')
        value = value.map((item) => parseInt(item.trim()))
        console.log(value)
        this.setState({ mpans: value })
    }

    async getMeteringData() {
        try{
            if(this.state.date === ""){
                alert("Please select a date")
                return
            }
            if(this.state.mpans.length === 0){
                alert("Please enter at least one mpan")
                return
            }
        const response = await axios.post(GET_METERING_BY_DATE,
            
              {
                date: this.state.date,
                mpans: this.state.mpans
            },
            {
                headers: {
                    Authorization: `bearer ${localStorage.getItem('token')}`
                }
            }
        )
        console.log(response.data)
        alert(response.data)
        }
        catch(err){
            alert(JSON.stringify(err.response.data))
        }
    }

    async downloadFiles(folder, filename) {
        const response = await axios.get(GET_FILE_URL, {

            headers: {
                Authorization: `bearer ${localStorage.getItem('token')}`
            },
            params: {
                folder,
                filename
            }

        });
        return response.data.url
    }

    async getAdminUploads() {
        const response = await axios.get(GET_ADMIN_UPLOADS, {

            headers: {
                Authorization: `bearer ${localStorage.getItem('token')}`
            },
            params: {
                type: "metering mpan by date"
            }

        });
        const files = []
        for (let i = 0; i < response.data.length; i++) {

            const item = response.data[i];
            const url = await this.downloadFiles(item.user, item.name);
            files.push({
                name: item.name,
                url
            })



            console.log(this.state.files)
        }
        this.setState({ files: files })
    }






    render() {
        return (
            <Container>
                <div>
                    <Calendar onChange={(date)=>{
                        console.log("date", date)
                        this.setState({ date: moment(date).format('YYYY-MM-DD')})
                    }} value={this.state.date} />
                  <b>  DATE:  {this.state.date}</b>
                  <br></br>
                  <b>MPANS</b>
                    <textarea value={this.state.mpans} onChange={
                        (event) => {
                            this.changeMpan(event)
                        }
                    } />
                    <button onClick={this.getMeteringData.bind(this)}>Get Metering Data</button>
                </div>
                <Row className='mt-5'>
                    <Col>
                    <h4>FILES Available for Download</h4>
                        <ul>
                            {console.log("INSIDE", this.state.files, this.state.files.length)}
                            {this.state.files.map((item) => {

                                return <li><a href={item.url} target='blank'>{item.name}</a></li>
                            })}
                        </ul>
                    </Col>
                </Row>
            </Container>
        )
    }
}

export default withRouter(MeteringByDate);
