import {Modal, Tab, Tabs} from 'react-bootstrap';
import react from 'react';
import React from 'react';
import {Line} from 'react-chartjs-2';
import Devices from './Devices';
import axios from 'axios';
import {GET_USER_DETAILS} from '../../env';
import Auth from '../../Auth';

class UserInformation extends react.Component {
  constructor(props) {
    super(props);
    this.state = {
      metering: [],
      devices: [],
      points: 0,
      reward: 0,
      show: true
    }
    this.setState({ show: this.props.show })
    this.get_user_details = this.get_user_details.bind(this)
  }

  componentWillMount() {
    this.get_user_details();
  }

  get_user_details() {
    axios.get(`${GET_USER_DETAILS}/${this.props.user._id}`, {
      headers: {
        Authorization: `bearer ${localStorage.getItem('token')}`,
      }
    }).then(
      res => {
        this.setState({ metering: res.data.metering })
        this.setState({ devices: res.data.devices })
        this.setState({ points: res.data.points })
        this.setState({ reward: res.data.reward })
      }
    ).catch(() => {
      Auth.force_logout(this.props.history.push)
    })
  }

  render() {
    const metering = []
    const date = []
    const len = this.state.metering.length
    let i = len >= 48 ? len - 48 : 0;
    for (; i < len; i++) {
      const time = this.state.metering[i].creation_date
      metering.push(this.state.metering[i].energy)
      date.push(time.split('T')[1].slice(0, 5))
    }
    const data = {
      labels: date,
      datasets: [{
        label: 'Metering (WATTS)',
        data: metering,
        fill: false,
        borderColor: 'rgb(54,75,75)',
        tension: 0.1
      }]
    };

    return (
      <Modal size="xl" show={this.state.show} onHide={this.props.hide} aria-labelledby="example-modal-sizes-title-lg">
        <Modal.Header closeButton>
          <Modal.Title id="example-modal-sizes-title-lg">
            {this.props.user.firstName} {this.props.user.lastName}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Tabs
            defaultActiveKey="devices"
            transition={false}
            id="noanim-tab-example"
            className="mb-3"
          >
            <Tab eventKey="devices" title="Devices">
              <Devices devices={this.state.devices}/>
            </Tab>
            <Tab eventKey="metering" title="Metering">
              <Line data={data} type='line'/>
            </Tab>
            <Tab eventKey="rewards" title="Rewards">
              <h5>Reward: £{this.state.reward.toFixed(2)}</h5>
              <h5>Points: {this.state.points.toFixed(2)}</h5>
            </Tab>
          </Tabs>
        </Modal.Body>
      </Modal>
    );
  }
}

export default UserInformation;
