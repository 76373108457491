import {withRouter} from 'react-router-dom';
import react from 'react';
import {Button, Col, Container, Form, Row, Table} from 'react-bootstrap';
import axios from 'axios';
import {ADD_MYENERGI_CUSTOMERS} from '../../../../env';
import Auth from '../../../../Auth';

class AddCustomers extends react.Component
{
  constructor(props)
  {
    super(props);
    this.state = {
      csv_file: [],
    }
    this.send_csv = this.send_csv.bind(this)
  }

  upload_csv(file)
  {
    const reader = new FileReader();
    reader.readAsText(file);
    reader.onload = () =>
    {
      let data = ''
      for (let i = 0; i < reader.result.length; ++i)
      {
        if (reader.result[i] !== '\n')
        {
          data += reader.result[i];
        } else
        {
          data = data.split(',')
          this.state.csv_file.push(
            {
              email: data[0],
              hub_serial_no: data[1],
              postcode: data[2].toLowerCase().replace(/ /g, ''),
              zappi: Number.parseInt(data[3]) === 0 ? 0: Number.parseInt(data[3]),
              eddi: Number.parseInt(data[4]) === 0 ? 0: Number.parseInt(data[4]),
            }
          )
          data = ''
        }
      }
    }
    reader.onerror = () =>
    {
    }
  }

  send_csv()
  {
    axios.post(ADD_MYENERGI_CUSTOMERS, {customers: this.state.csv_file}, { headers: { Authorization: `bearer ${localStorage.getItem('token')}`, } }).then(
      res =>
      {
      }
    ).catch(() =>
    {
      Auth.force_logout(this.props.history.push)
    })
  }

  render()
  {
    return (
      <Container>
        <Row className='mt-5'>
          <Row>
            <Col>
              <Form.Group controlId="formFile" className="mb-3">
                <Form.Label>CSV file input</Form.Label>
                <Form.Control size='lg' type="file" onChange={e => this.upload_csv(e.target.files[0])}/>
              </Form.Group>
            </Col>
            <Col style={{ 'margin-top': '35px' }}>
              <Button onClick={() => this.setState({ csv_file: this.state.csv_file })}>Upload</Button>
            </Col>
            <Col style={{ 'margin-left': '-500px', 'margin-top': '35px' }}>
              <Button onClick={this.send_csv}>Send</Button>
            </Col>
          </Row>
          <Col>
            <Table striped bordered hover variant='dark'>
              <thead>
              <tr>
                <th>Email</th>
                <th>Hub Serial Number</th>
                <th>Postcode</th>
                <th>Zappi</th>
                <th>Eddi</th>
              </tr>
              </thead>
              <tbody>
              {this.state.csv_file.map((customer, index) => (
                  <tr>
                    <td>{customer.email}</td>
                    <td>{customer.hub_serial_no}</td>
                    <td>{customer.postcode}</td>
                    <td>{customer.zappi}</td>
                    <td>{customer.eddi}</td>
                  </tr>
                )
              )}
              </tbody>
            </Table>
          </Col>
        </Row>
      </Container>
    );
  }
}

export default withRouter(AddCustomers);
