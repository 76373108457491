import React from 'react';
import {Container, Button, Nav, Navbar, NavDropdown} from 'react-bootstrap';
import {Link, Route, withRouter} from 'react-router-dom';
import Users from '../Users/Users';
import react from 'react';
import Dashboard from '../Dashboard/Dashboard';
import GridEvents from '../GridEvents/GridEvents';
import Partners from '../Partners/Partners';
import Auth from '../../Auth';
import EvSummary from '../Ev/EvSummary/EvSummary';
import AdminRoutes from '../Admin/AdminRoutes';
import AccessControl from "../AccessControl/AccessControl";

class Navigation extends react.Component {
  constructor(props) {
    super(props);
    this.logout = this.logout.bind(this);
  }

  logout() {
    localStorage.setItem('token', '')
    localStorage.setItem('is_authenticated', 'false')
    this.props.history.push('/login')
  }

  render() {
    return (
      <>
        {Auth.is_authenticated() && Auth.is_admin() &&
          <>
            <Navbar className='custom-nav-color shadow p-3 mb-5 bg-green rounded' bg='light' expand='lg'>
              <Container fluid>
                <Navbar.Brand className='logo-color'>Orange Power</Navbar.Brand>
                <Navbar.Toggle aria-controls='navbarScroll'/>
                <Navbar.Collapse id='navbarScroll'>
                  <Nav
                    className='me-auto mx-auto my-2 my-lg-0' style={{maxHeight: '100px'}} navbarScroll>
                    <Nav.Link><Link to='/'>Dashboard</Link></Nav.Link>
                    <NavDropdown title='Access Control' id='navbarScrollingDropdown'>
                      <NavDropdown.Item>
                        <Nav.Link>
                          <Link to='/access-control/permissions'>
                            Permission
                          </Link>
                        </Nav.Link>
                      </NavDropdown.Item>
                      <NavDropdown.Item>
                        <Nav.Link>
                          <Link to='/access-control/roles'>
                            Roles
                          </Link>
                        </Nav.Link>
                      </NavDropdown.Item>
                      <NavDropdown.Item>
                        <Nav.Link>
                          <Link to='/access-control/admins'>
                            Admins
                          </Link>
                        </Nav.Link>
                      </NavDropdown.Item>
                    </NavDropdown>
                    <NavDropdown title='Admin' id='navbarScrollingDropdown'>
                      <NavDropdown.Item>
                        <Nav.Link>
                          <Link to='/admin/instructions'>
                            Instructions
                          </Link>
                        </Nav.Link>
                      </NavDropdown.Item>
                      <NavDropdown.Item>
                        <Nav.Link>
                          <Link to='/admin/mpan-address-verify'>
                            MPAN Address Verify
                          </Link>
                        </Nav.Link>
                      </NavDropdown.Item>

                      <NavDropdown.Item>
                        <Nav.Link>
                          <Link to='/admin/mpan/metering-by-date'>
                            Metering By Date
                          </Link>
                        </Nav.Link>
                      </NavDropdown.Item>

                      <NavDropdown.Item>
                        <Nav.Link>
                          <Link to='/admin/mpan/export-mpans'>
                            Export Mpan CSV
                          </Link>
                        </Nav.Link>
                      </NavDropdown.Item>

                      <NavDropdown.Item>
                        <Nav.Link>
                          <Link to='/admin/mpan/n3rgy'>
                           N3rgy Baseline/Metering
                          </Link>
                        </Nav.Link>
                      </NavDropdown.Item>

                      <NavDropdown.Item>
                        <Nav.Link>
                          <Link to='/admin/user-metrics'>
                            User Metrics Data
                          </Link>
                        </Nav.Link>
                      </NavDropdown.Item>
                      <NavDropdown drop='end' title='Paypal' id='navbarScrollingDropdown'>
                        <NavDropdown.Item>
                          <Nav.Link>
                            <Link to='/admin/paypal/redeem-requests'>
                              Redeem Requests
                            </Link>
                          </Nav.Link>
                        </NavDropdown.Item>
                        <NavDropdown.Item>
                          <Nav.Link>
                            <Link to='/admin/paypal/redeem-requests/history'>
                              Redeem Requests History
                            </Link>
                          </Nav.Link>
                        </NavDropdown.Item>
                      </NavDropdown>
                    </NavDropdown>
                    <NavDropdown title='Customers' id='navbarScrollingDropdown'>
                      <NavDropdown.Item>
                        <Nav.Link>
                          <Link to='/users'>
                            View Customers
                          </Link>
                        </Nav.Link></NavDropdown.Item>
                    </NavDropdown>
                    <NavDropdown title='Grid' id='navbarScrollingDropdown'>
                      <NavDropdown drop='end' title='Flex' id='navbarScrollingDropdown'>
                        <NavDropdown.Item>Flex Request</NavDropdown.Item>
                        <NavDropdown.Item>Flex Offer</NavDropdown.Item>
                        <NavDropdown.Item>Flex Order</NavDropdown.Item>
                        <NavDropdown.Item>Flex Settlement</NavDropdown.Item>
                        <NavDropdown.Item>Flex Reservation Update</NavDropdown.Item>
                        <NavDropdown.Item>Flex Offer Revocation</NavDropdown.Item>
                      </NavDropdown>
                      <NavDropdown.Divider/>
                      <NavDropdown.Item>D-Prognosis</NavDropdown.Item>
                      <NavDropdown.Item>AGR Query</NavDropdown.Item>
                      <NavDropdown.Divider/>
                      <NavDropdown.Item><Link to='/grid-events'>Grid Events</Link></NavDropdown.Item>
                    </NavDropdown>
                    <NavDropdown title='EV' id='navbarScrollingDropdown'>
                      <NavDropdown.Item>
                        <Nav.Link>
                          <Link to='/ev/summary'>Summary</Link>
                        </Nav.Link>
                      </NavDropdown.Item>
                    </NavDropdown>
                    <NavDropdown title='Partners' id='navbarScrollingDropdown'>
                      <NavDropdown drop='end' title='Myenergi' id='navbarScrollingDropdown'>
                        <NavDropdown.Item>
                          <Nav.Link><Link to='/partners/myenergi/customers'>Customers</Link></Nav.Link>
                        </NavDropdown.Item>
                        <NavDropdown.Item>
                          <Nav.Link><Link to='/partners/myenergi/add-customers'>Add Customers</Link></Nav.Link>
                        </NavDropdown.Item>
                      </NavDropdown>
                    </NavDropdown>
                  </Nav>
                  <Button variant='outline-danger' onClick={this.logout}>Logout</Button>
                </Navbar.Collapse>
              </Container>
            </Navbar>
            <Route exact path='/admin/*'>
              <AdminRoutes/>
            </Route>
            <Route exact path='/users'>
              <Users/>
            </Route>
            <Route exact path='/access-control/*'>
              <AccessControl/>
            </Route>
            <Route exact path='/ev/summary'>
              <EvSummary/>
            </Route>
            <Route exact path='/grid-events'>
              <GridEvents/>
            </Route>
            <Route exact path='/'>
              <Dashboard/>
            </Route>
          </>
        }

        <Route exact path='/partners/*'>
          <Partners/>
        </Route>

      </>
    );
  }
}

export default withRouter(Navigation);
