import react from 'react';
import {Button, Card, Container, FormControl, InputGroup, Modal, Nav, ProgressBar, Row} from 'react-bootstrap';
import axios from 'axios';
import {DELETE_SCHEDULE, GET_SCHEDULES} from '../../env';
import {withRouter} from 'react-router-dom';
import EventAdder from './EventAdder';
import Auth from '../../Auth';
import React from 'react';

class GridEvents extends react.Component
{
  constructor(props)
  {
    super(props);
    this.state = {
      schedules: [],
      countdown: [],
      show_confirm_deletion: false,
      schedule_id: '',
      interval_id: 0
    }
    this.delete_schedule = this.delete_schedule.bind(this)
  }

  componentDidMount()
  {
    this.get_schedules();  // TODO: update it every 5 min or so
    const interval_id = setInterval(() =>
    {
      this.setState({ schedules: this.state.schedules })
      this.display_event();
      this.get_schedules();
    }, 1000)
    this.setState({ interval_id })
  }

  componentWillUnmount()
  {
    clearInterval(this.state.interval_id)
  }

  get_schedules()
  {
    axios.get(GET_SCHEDULES, { headers: { Authorization: `bearer ${localStorage.getItem('token')}`, } }).then(
      res =>
      {
        this.setState({ schedules: res.data })
      }
    ).catch(() =>
    {
      Auth.force_logout(this.props.history.push)
    })
  }

  delete_schedule()
  {
    axios.post(DELETE_SCHEDULE, { schedule_id: this.state.schedule_id }, { headers: { Authorization: `bearer ${localStorage.getItem('token')}`, } }).then(
      (_) => this.setState({ show_confirm_deletion: false, schedule_id: '' })
    )
      .catch(() =>
      {
        Auth.force_logout(this.props.history.push)
      })
  }

  handle_datetime(datetime)
  {
    if (datetime)
    {
      const date = new Date(datetime);
      return `${date.getFullYear()}/${date.getMonth() + 1}/${date.getDate() + 1} ${date.getHours()}:${date.getMinutes()}:${date.getSeconds()}`
    } else
    {
      return  datetime ?? 'Waiting...'
    }
  }

  display_event()
  {
    let data = [];
    this.state.countdown = []
    const events = this.state.schedules;
    for (let i = 0; i < events.length; ++i)
    {
      const variant = events[i].status === 0 ? 'Info' : events[i].status === 1 ? 'success' : 'Dark';
      const status = events[i].status === 0 ? 'Idle' : events[i].status === 1 ? 'Started' : 'Ended'
      const now = new Date();
      const timestamp_start = new Date(events[i].start)
      const timestamp_end = new Date(events[i].end)
      let progress = 0.00;

      const timer = timestamp_end - timestamp_start
      let days = Math.floor(timer / 86400000);
      let hours = Math.floor((timer % 86400000) / 3600000);
      let minutes = Math.floor((timer % 3600000) / 60000);
      let seconds = Math.floor((timer % 60000) / 1000);

      const distance = timestamp_end - now
      if (distance > 0 && now >= timestamp_start)
      {
        days = Math.floor(distance / 86400000); // (1000 * 60 * 60 * 24)
        hours = Math.floor((distance % 86400000) / 3600000); // (1000 * 60 * 60)
        minutes = Math.floor((distance % 3600000) / 60000); // (1000 * 60)
        seconds = Math.floor((distance % 60000) / 1000);
      }

      hours = String(hours).padStart(2, '0')
      minutes = String(minutes).padStart(2, '0')
      seconds = String(seconds).padStart(2, '0')
      const countdown = `${days}:${hours}:${minutes}:${seconds}`
      this.state.countdown.push(countdown)

      if (now > timestamp_start && now < timestamp_end)
      {
        const start_to_end = timestamp_end - timestamp_start;
        const step = start_to_end / 100;
        const current = now - timestamp_start
        progress = (current / step).toFixed(2);
      } else if (now > timestamp_end)
      {
        progress = 100.00;
      }

      data.push(
        <Card bg={variant.toLowerCase()} key={1} text='white' style={{ width: '18rem' }} className="mb-2 m-3">
          <Card.Header>
            <h3 className='text-center'>{this.state.countdown[i]}</h3>
            <ProgressBar animated now={progress} label={`${progress}%`}/>
          </Card.Header>
          <Card.Body>
            <Card.Title>Event {status}</Card.Title>
            <Card.Title>Postcode: {events[i].postcode}</Card.Title>
            <Card.Text>Status: {status}</Card.Text>
            <Card.Text>Start: {this.handle_datetime(events[i].start)}</Card.Text>
            <Card.Text>End: {this.handle_datetime(events[i].end)}</Card.Text>
            <Card.Text>Actual start: {this.handle_datetime(events[i].started)}</Card.Text>
            <Card.Text>Actual end: {this.handle_datetime(events[i].ended)}</Card.Text>
            <Button variant='danger' onClick={() => this.setState({
              show_confirm_deletion: true,
              schedule_id: events[i]._id
            })}>Delete</Button>
            <Button variant='primary'>Edit</Button>
          </Card.Body>
        </Card>
      )
    }
    return data
  }

  render()
  {
    return (
      <Container>
        <Row className='mt-5'>
          <Nav as="ul">
            <Nav.Item as="li">
              <InputGroup className="mb-2">
                <InputGroup.Text>Start</InputGroup.Text>
                <FormControl type='date' id="inlineFormInputGroup"/>
              </InputGroup>
            </Nav.Item>
            <Nav.Item as="li">
              <InputGroup className="mb-2">
                <InputGroup.Text>End</InputGroup.Text>
                <FormControl type='date' id="inlineFormInputGroup"/>
              </InputGroup>
            </Nav.Item>
            <Nav.Item as="li">
              <Button className='mx-3'>Filter</Button>
            </Nav.Item>
            <EventAdder/>
          </Nav>
          {this.display_event()}
        </Row>
        <Modal size="sm" show={this.state.show_confirm_deletion}
               onHide={() => this.setState({ show_confirm_deletion: false })}
               aria-labelledby="example-modal-sizes-title-lg">
          <Modal.Header closeButton>
            <Modal.Title id="example-modal-sizes-title-lg">
              Confirm Deletion
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <h6>Are you sure you want to delete this event.</h6>
            <h6>This action CANNOT be reversed.</h6>
          </Modal.Body>
          <Button variant='danger' style={{ 'border-radius': 0 }} onClick={this.delete_schedule}>Delete</Button>
        </Modal>
      </Container>
    )
  }
}

export default withRouter(GridEvents);
