import React, {useEffect, useState} from "react";
import axios from "axios";
import {
  CREATE_ADMIN,
  DELETE_ADMIN,
  GET_ADMINS,
  GET_ROLES, UPDATE_ADMIN,
} from "../../env";
import {Button, Container, Dropdown, Form, Modal, Table} from "react-bootstrap";

export default function Admins() {
  const [admins, setAdmins] = useState([])
  const [admin, setAdmin] = useState({
    _id: '',
    first_name: '',
    last_name: '',
    email: '',
    password: '',
    roles: [],
  })
  const [roles, setRoles] = useState([])
  const [showConfirmDeletion, setShowConfirmDeletion] = useState(false)
  const [showCreateAdmin, setShowCreateAdmin] = useState(false)
  const [showEditAdmin, setShowEditAdmin] = useState(false)

  useEffect(() => {
    getAdmins()
    getRoles()
  }, []);
  const getAdmins = () => {
    const token = `bearer ${localStorage.getItem('token')}`
    axios.get(GET_ADMINS,
      {headers: {Authorization: token}})
      .then(res => {
        setAdmins(res.data)
      })
  }
  const createAdmin = () => {
    const data = {
      first_name: admin.first_name,
      last_name: admin.last_name,
      email: admin.email,
      password: admin.password,
    }
    const token = `bearer ${localStorage.getItem('token')}`
    axios.post(CREATE_ADMIN, data,
      {headers: {Authorization: token}})
      .then(_ => {
          setAdmin({
            _id: '',
            first_name: '',
            last_name: '',
            email: '',
            password: '',
            roles: [],
          })
          setShowCreateAdmin(false)
          getAdmins()
        }
      )
  }
  const getRoles = () => {
    const token = `bearer ${localStorage.getItem('token')}`
    axios.get(GET_ROLES, {headers: {Authorization: token}})
      .then(res => {
        setRoles(res.data)
      })
  }

  const editAdmin = (admin) => {
    setAdmin(admin)
    setShowEditAdmin(true)
  }

  const updateAdmin = () => {
    const data = {
      first_name: admin.first_name,
      last_name: admin.last_name,
      email: admin.email,
      roles: admin.roles.map(role => role._id)
    }
    const token = `bearer ${localStorage.getItem('token')}`
    axios.put(`${UPDATE_ADMIN}/${admin._id}`, data,
      {headers: {Authorization: token}})
      .then(_ => {
        setAdmin({
          _id: '',
          first_name: '',
          last_name: '',
          email: '',
          password: '',
          roles: [],
        })
        setShowEditAdmin(false)
        getAdmins()
      })
  }
  const confirmDeletion = (admin) => {
    setAdmin(admin)
    setShowConfirmDeletion(true)
  }

  const deleteAdmin = () => {
    const token = `bearer ${localStorage.getItem('token')}`
    axios.delete(`${DELETE_ADMIN}/${admin._id}`,
      {
        headers: {Authorization: token}
      }).then(_ => {
      setAdmin({
        _id: '',
        first_name: '',
        last_name: '',
        email: '',
        password: '',
        roles: [],
      })
      setShowConfirmDeletion(false)
      getAdmins()
    })
  }
  const addRole = (role) => {
    if (admin.roles.includes(role)) {
      return
    }
    setAdmin({...admin, roles: [...admin.roles, role]})
  }

  const removeRole = (role) => {
    setAdmin({...admin, roles: admin.roles.filter(r => r._id !== role._id)})
  }

  return (
    <Container>
      <Button onClick={() => setShowCreateAdmin(true)}>Create Admin</Button>
      <Table striped bordered hover variant='dark'>
        <thead>
        <tr>
          <th>ID</th>
          <th>First Name</th>
          <th>Last Name</th>
          <th>Email</th>
          <th>Roles</th>
          <th>Actions</th>
        </tr>
        </thead>
        <tbody>
        {admins.map((admin, index) => {
          return (
            <tr key={index}>
              <td>{admin._id}</td>
              <td>{admin.first_name}</td>
              <td>{admin.last_name}</td>
              <td>{admin.email}</td>
              <td>
                <ul>
                  {admin.roles.map((role, index) => {
                    return (
                      <li key={index}>{role.name}</li>
                    )
                  })}
                </ul>
              </td>
              <td>
                <Button className='mb-2' variant='primary' onClick={() => editAdmin(admin)}>Edit</Button>
                <Button variant='danger' onClick={() => confirmDeletion(admin)}>Delete</Button>
              </td>
            </tr>
          )
        })}
        </tbody>
      </Table>
      <Modal size="sm" show={showCreateAdmin}
             onHide={() => setShowCreateAdmin(false)}>
        <Modal.Header closeButton>
          <Modal.Title>
            Create Admin
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className='mb-3' controlId='formGroupEmail'>
              <Form.Control type='text' placeholder='First name'
                            onChange={value => setAdmin({...admin, first_name: value.target.value})}/>
              <br/>
              <Form.Control type='text' placeholder='Last name'
                            onChange={value => setAdmin({...admin, last_name: value.target.value})}/>
              <br/>
              <Form.Control type='email' placeholder='Email'
                            onChange={value => setAdmin({...admin, email: value.target.value})}/>
              <br/>
              <Form.Control type='password' placeholder='Password'
                            onChange={value => setAdmin({...admin, password: value.target.value})}/>
              <br/>
            </Form.Group>
          </Form>
        </Modal.Body>
        <Button variant='primary btn-block' style={{'border-radius': 0}} onClick={createAdmin}>Create</Button>
      </Modal>

      <Modal size="sm" show={showEditAdmin}
             onHide={() => setShowEditAdmin(false)}>
        <Modal.Header closeButton>
          <Modal.Title>
            Edit Admin <mark>{admin.first_name}</mark>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className='mb-3' controlId='formGroupEmail'>
              <Form.Control type='text' placeholder='First name' value={admin.first_name}
                            onChange={value => setAdmin({...admin, first_name: value.target.value})}/>
              <br/>
              <Form.Control type='text' placeholder='Last name' value={admin.last_name}
                            onChange={value => setAdmin({...admin, last_name: value.target.value})}/>
              <br/>
              <Form.Control type='email' placeholder='Email' value={admin.email}
                            onChange={value => setAdmin({...admin, email: value.target.value})}/>
              <br/>
            </Form.Group>
            <Dropdown className='mb-4'>
              <Dropdown.Toggle variant="success" id="dropdown-basic">
                Roles
              </Dropdown.Toggle>
              <Dropdown.Menu>
                {roles.map((role, _) => (
                  <Dropdown.Item onClick={() => addRole(role)}>{role.name}</Dropdown.Item>
                ))}
              </Dropdown.Menu>
            </Dropdown>
          </Form>
          <ul>
            {admin.roles.map((role, index) => {
              return (
                <li key={index} onClick={() => removeRole(role)}>{role.name}</li>
              )
            })}
          </ul>
        </Modal.Body>
        <Button variant='primary btn-block' style={{'border-radius': 0}} onClick={updateAdmin}>Edit</Button>
      </Modal>

      <Modal size="sm" show={showConfirmDeletion}
             onHide={() => setShowConfirmDeletion(false)}
             aria-labelledby="example-modal-sizes-title-lg">
        <Modal.Header closeButton>
          <Modal.Title id="example-modal-sizes-title-lg">
            Confirm Deletion
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h6>Are you sure you want to delete this admin
            <mark>{admin.first_name}</mark>?
          </h6>
          <h6>This action CANNOT be reversed.</h6>
        </Modal.Body>
        <Button variant='danger' style={{'border-radius': 0}} onClick={deleteAdmin}>Delete</Button>
      </Modal>
    </Container>
  )

}
