import {Button, Col, Row, Table, Form, Container, Modal} from 'react-bootstrap';
import axios from 'axios'
import {DELETE_USER, GET_USERS} from '../../env';
import UserInformation from './UserInformation';
import react from 'react';
import {withRouter} from 'react-router-dom';
import Auth from '../../Auth';
import React from 'react';

class Users extends react.Component {
  constructor(props) {
    super(props);
    this.state = {
      users: [],
      filtered_users: [],
      user: {},
      show: false,
      show_confirm_deletion: false,
      user_id: '',
      filter: ''
    };
    this.hide = this.hide.bind(this)
    this.confirm_deletion = this.confirm_deletion.bind(this)
    this.delete_user = this.delete_user.bind(this)
  }

  componentDidMount() {
    this.get_users();
  }

  get_users() {
    axios.get(GET_USERS, { headers: { Authorization: `bearer ${localStorage.getItem('token')}`, } }).then(
      res => {
        const users = res.data
        for (let i = 0; i < users.length; ++i) {
          try {
            users[i].apis = users[i].apis.join(' / ')
          } catch (e) {
          }
        }
        this.setState({ users })
        this.setState({ filtered_users: users })
      }
    ).catch(() => {
      Auth.force_logout(this.props.history.push)
    })
  }

  hide() {
    this.setState({ show: false })
  }

  display_user_details(id) {
    this.setState({ user: this.state.filtered_users[id] });
    this.setState({ show: true })
  }

  filter(key) {
    const filter = this.state.filter.split(':')
    this.setState({ filtered_users: this.state.users })
    try {
      if (key.charCode === 13) {
        this.setState({ filtered_users: this.state.users.filter(obj => obj[filter[0]].includes(filter[1])) })
      }
    } catch (_) {
      this.setState({ filtered_users: this.state.users })
    }
  }

  confirm_deletion(user_id) {
    this.setState({ user_id })
    this.setState({ show_confirm_deletion: true })
  }

  async delete_user(user_id) {
    await axios.post(DELETE_USER, { user_id });
    this.forceUpdate();
  }

  render() {
    return (
      <Container>
        <Row className='mt-5'>
          <Form.Control onKeyPress={key => this.filter(key)} className='mb-3' size="lg" type="text" placeholder="Filter"
                        onChange={value => this.setState({ filter: value.target.value })}/>
          <Col>
            <Table striped bordered hover variant='dark'>
              <thead>
              <tr>
                <th>First Name</th>
                <th>Last Name</th>
                <th>Email</th>
                <th>Postcode</th>
                <th>Paypal</th>
                <th>Points</th>
                <th>Redeemed Points</th>
                <th>AAGR</th>
                <th>Connected APIs</th>
                <th>Action</th>
              </tr>
              </thead>
              <tbody>
              {this.state.filtered_users.map((user, index) => (
                  <tr>
                    <td>{user.firstName}</td>
                    <td>{user.lastName}</td>
                    <td>{user.email}</td>
                    <td>{user.postCode}</td>
                    <td>{user.paypal_email}</td>
                    <td>{user.points}</td>
                    <td>{user.redeemed_points}</td>
                    <td>{user.auto_attend_grid_events === true ? 'True' : 'False'}</td>
                    <td>{user.apis}</td>
                    <td>
                      <Button className='mb-2' variant='primary'
                              onClick={() => this.display_user_details(index)}>Details</Button>
                      <Button variant='danger' onClick={() => this.confirm_deletion(user._id)}>Delete</Button>
                    </td>
                  </tr>
                )
              )}
              </tbody>
            </Table>
          </Col>
          <Modal size="sm" show={this.state.show_confirm_deletion}
                 onHide={() => this.setState({ show_confirm_deletion: false })}
                 aria-labelledby="example-modal-sizes-title-lg">
            <Modal.Header closeButton>
              <Modal.Title id="example-modal-sizes-title-lg">
                Confirm Deletion
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <h6>Are you sure you want to delete this user.</h6>
              <h6>This action CANNOT be reversed.</h6>
            </Modal.Body>
            <Button variant='danger' style={{ 'border-radius': 0 }} onClick={() => this.delete_user()}>Delete</Button>
          </Modal>
          {this.state.show === true && <UserInformation hide={this.hide} user={this.state.user}/>}
        </Row>
      </Container>
    );
  }
}

export default withRouter(Users);
