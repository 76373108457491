import react from 'react';
import {
  Col,
  Container, Pagination,
  Row,
  Table,
} from 'react-bootstrap';
import axios from 'axios';
import {withRouter} from 'react-router-dom';
import React from 'react';
import {GET_MPANS_ADDRESS, GET_FILE_URL} from '../../../env';
import Auth from '../../../Auth';

class MpanAddressVerify extends react.Component {
  constructor(props) {
    super(props);
    this.state = {
      count: 0,
      mpans: [],
      userInfo: {},
      skip: 0,
      limit: 0,
      page_index: 0,
      gap: 10,
    }

    this.get_all_mpan = this.get_all_mpan.bind(this)
  }

  componentDidMount() {
    this.state.limit = this.state.gap;
    this.get_all_mpan();
  }

  get_pagination() {
    const pages = []
    let page_index = 1;
    for (let i = 0; i < this.state.count; i += this.state.gap) {
      if (this.state.page_index === i) {
        pages.push(<Pagination.Item active onClick={() => this.get_page(i)}>{page_index}</Pagination.Item>)
      } else {
        pages.push(<Pagination.Item onClick={() => this.get_page(i)}>{page_index}</Pagination.Item>)
      }
      ++page_index;
    }
    return pages;
  }

  get_page(skip) {
    this.setState({
      skip: skip,
      limit: skip + this.state.gap,
      page_index: skip,
    }, () => {
      this.get_all_mpan();
    })
  }


  update_status(user, status, mpan, moveInDate, index) {
    // eslint-disable-next-line no-restricted-globals
    if (!user) {
      alert('No user found');
      return;
    }

    // eslint-disable-next-line no-restricted-globals
    if (confirm('Are you sure?')) {
      let reason = '';
      if (status === false) {

        reason = prompt("Enter the Reason", "");
        if (reason == null || reason.trim() === "") {
          alert("You must enter a reason");
          return;
        }

      }


      axios.put(`${GET_MPANS_ADDRESS}/update-status`,
        {
          user,
          status: status,
          mpan,
          reason: reason
        },
        {
          headers: {
            Authorization: `bearer ${localStorage.getItem('token')}`
          }
        }).then(
        res => {
          this.setState({ mpans: this.state.mpans.filter((_, i) => i !== index) });
        })
        .catch(error => {
          alert('Something went wrong => ' + error.response.data);
          console.log(error.response.data);
        })
    }
  }

  get_all_mpan() {
    axios.get(`${GET_MPANS_ADDRESS}`,
      {
        headers: {
          Authorization: `bearer ${localStorage.getItem('token')}`
        },
        params: {
          with_user: true,
          skip: this.state.skip,
          limit: this.state.limit,
        }
      }).then(
      async (res) => {

        console.log("DATA", res.data)
        for (let i = 0; i < res.data.mpans.length; i++) {
          const mpan = res.data.mpans[i];
          const uploads = mpan.user.uploads;
          let bill_image = uploads.find(img => img.type === 'bill');
          const n =  mpan.user.resubmit?mpan.user.resubmit.length :0;
          if(n>0){
            bill_image= uploads.find(img => {
              return img.object_id === mpan.user.resubmit[n-1].property;
            });
          }

          if (!uploads || !bill_image) {
            console.log("CONTINUE")
            continue;
          }

          let [folder, filename] = (decodeURI(bill_image.url).split('amazonaws.com/'))[1].split('/');
          filename = filename.split('?')[0];
          const response = await axios(GET_FILE_URL, {

            headers: {
              Authorization: `bearer ${localStorage.getItem('token')}`
            },
            params: {
              folder,
              filename
            }

          });
          bill_image.src = response.data.url;
        }
        
        this.setState({ count: res.data.count })
        this.setState({ mpans: res.data.mpans })
        this.setState({ userInfo: res.data.userInfo })
      }
    ).catch((error) => {
      if (error.response && error.response.status === 403) {
        Auth.force_logout(this.props.history.push)
      }

    })
  }


  render() {
    return (
      <Container>
        <span>
        <b>All Users: </b> {this.state.userInfo.allusers || 0} <b>MPAN Users: </b>{this.state.userInfo.total || 0} <b>All Consented Users: </b>{this.state.userInfo.approved || 0}
          <b> Not Yet Consented Users: </b> {this.state.userInfo.unapproved || 0}
          <b>Rejected by Admin: </b> {this.state.userInfo.rejected || 0}
        </span>
        <Row className='mt-5'>
          <Col>
            <Table striped bordered hover variant='dark'>
              <thead>
              <tr>
                <th>Sr.no</th>
                <th>MPAN</th>
                <th>Name</th>
                <th>Email</th>
                <th>Address Status</th>
                <th>Address</th>
                <th>Postcode</th>
                <th>Bill Proof</th>
                <th> Action</th>


              </tr>
              </thead>
              <tbody>
              {this.state.mpans.map((request, index) => {
                  if (!request.user) {
                    request.user = {
                      name: ' NO USER',
                      email: ' NO USER',
                      is_address_approved: { value: false },
                      _id: null,
                    };
                  }
                  return (<tr key={index}>
                      <td>{index + 1}</td>
                      <td>{request.mpan}</td>
                      <td>{request.user.name}</td>
                      <td>{request.user.email}</td>
                      <td>{request.user.is_address_approved && request.user.is_address_approved.value ? 'Approved' : 'Rejected'}</td>
                      <td>{request.address_line_1}<br/>
                        {request.address_line_2}<br/>
                        {request.address_line_3}<br/>
                        {request.address_line_4}<br/>
                        {request.address_line_5}<br/>
                        {request.address_line_6}<br/>
                        {request.address_line_7}<br/>
                        {request.address_line_8}<br/>
                        {request.address_line_9}<br/>
                      </td>

                      <td>{request.postcode}</td>
                      <td>
                        {
                          (() => {
                            const uploads = request.user.uploads;
                            if (!uploads) return '';

                            let bill_image = uploads.find(img => img.type === 'bill');
                            const n = request.user.resubmit?request.user.resubmit.length :0;
                            if(n>0){
                              bill_image= uploads.find(img => img.object_id ===request.user.resubmit[n-1].property);
                            }

                            if (!bill_image) return '';
                            const type = bill_image.object_id.split('.').pop();
                            console.log(type)
                            if (type === 'pdf') {
                              return (<a href={bill_image.src} rel="noreferrer" target="_blank">

                                <embed src={bill_image.src} width="400" height="400"></embed>
                              </a>)
                            }
                            return (<a href={bill_image.src} rel="noreferrer" target="_blank">
                              <img with='400' height='400' src={bill_image.src} alt="bill"/>
                            </a>)

                          })()
                        }
                      </td>

                      <td>
                        <button className='btn btn-success' onClick={() => {
                          const uploads = request.user.uploads;
                          const bill_image = uploads.find(img => img.type === 'bill');

                          if (!uploads || !bill_image) {
                            alert('Bill image not found');
                            return '';
                          }
                          this.update_status(request.user._id, true, request.mpan, request.move_in_date, index)

                        }}>
                          {
                            request.user.is_address_approved && request.user.is_address_approved.value ? 'Reject' : 'Approve'
                          }

                        </button>
                        <br/>
                        <br/>
                        <button className='btn btn-danger'
                                onClick={() => {
                                  const uploads = request.user.uploads;
                                  const bill_image = uploads.find(img => img.type === 'bill');

                                  if (!uploads || !bill_image) {
                                    alert('Bill image not found');
                                    return '';
                                  }
                                  this.update_status(request.user._id, false, request.mpan, request.move_in_date, index)

                                }}
                        >Reject
                        </button>

                      </td>
                    </tr>

                  )
                }
              )}
              </tbody>
            </Table>
          </Col>
          <Pagination>
            {this.get_pagination()}
          </Pagination>
        </Row>
      </Container>
    )
  }
}

export default withRouter(MpanAddressVerify);
