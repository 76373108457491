import {Col, Row} from 'react-bootstrap';
import react from 'react';
import {Line} from 'react-chartjs-2';


class Devices extends react.Component
{
  constructor(props)
  {
    super(props);
    this.sta = {
      0: 'Starting',
      1: 'Waiting for export',
      2: 'DSR',
      3: 'Diverting',
      4: 'Boosting',
      5: 'Hot',
      6: 'Stopped'
    }
  }

  render()
  {
    const metering = []
    const date = []
    const len = this.props.eddi._metering.count
    let i = len >= 48 ? len - 48 : 0;
    for (; i < len; i++)
    {
      metering.push(this.props.eddi._metering.metering[i].energy)
      const time = this.props.eddi._metering.metering[i].creation_date
      date.push(time.split('T')[1].slice(0, 5))
    }
    const data = {
      labels: date,
      datasets: [{
        label: 'Metering (WATTS)',
        data: metering,
        fill: false,
        borderColor: 'rgb(54,75,75)',
        tension: 0.1
      }]
    };

    return (
      <Row>
        <Col>
          <h5>Eddi: {this.props.eddi.device_id}</h5>
        </Col>
        <Col>
          <h5>Status: {this.sta[this.props.eddi.sta]}</h5>
        </Col>
        <h5>Reward: £{this.props.eddi.reward.toFixed(2)}</h5>
        <h5>Points earned: {this.props.eddi.points.toFixed(2)}</h5>
        <Line data={data} type='line'/>
      </Row>
    );
  }
}

export default Devices;
