import axios from "axios";
import {CREATE_PERMISSION, DELETE_PERMISSION, GET_PERMISSIONS, UPDATE_PERMISSION} from "../../env";
import React, {useEffect, useState} from "react";
import {Button, Container, Form, Modal, Table} from "react-bootstrap";

export default function Permissions() {
  const [permissions, setPermissions] = useState([])
  const [permissionName, setPermissionName] = useState('')
  const [permissionDescription, setPermissionDescription] = useState('')
  const [permission, setPermission] = useState({})
  const [showConfirmDeletion, setShowConfirmDeletion] = useState(false)
  const [showCreatePermission, setShowCreatePermission] = useState(false)
  const [showEditPermission, setShowEditPermission] = useState(false)

  useEffect(() => {
    getPermissions()
  }, []);
  const createPermission = () => {
    const data = {
      name: permissionName,
      description: permissionDescription
    }
    const token = `bearer ${localStorage.getItem('token')}`
    axios.post(CREATE_PERMISSION, data,
      {headers: {Authorization: token}})
      .then(_ => {
        setPermissionName('')
        setPermissionDescription('')
        setShowCreatePermission(false)
        getPermissions()
      })
  }
  const getPermissions = () => {
    const token = `bearer ${localStorage.getItem('token')}`
    axios.get(GET_PERMISSIONS,
      {headers: {Authorization: token}})
      .then(res => {
        setPermissions(res.data)
      })
  }
  const editPermission = (permission) => {
    setPermission(permission)
    setShowEditPermission(true)
  }
  const updatePermission = () => {
    const data = {
      name: permissionName,
      description: permissionDescription
    }
    const token = `bearer ${localStorage.getItem('token')}`
    axios.put(`${UPDATE_PERMISSION}/${permission._id}`, data,
      {headers: {Authorization: token}})
      .then(_ => {
        setPermissionName('')
        setPermissionDescription('')
        setPermission({})
        setShowEditPermission(false)
        getPermissions()
      })
  }
  const confirmDeletion = (permission) => {
    setPermission(permission)
    setShowConfirmDeletion(true)
  }
  const deletePermission = () => {
    const token = `bearer ${localStorage.getItem('token')}`
    axios.delete(`${DELETE_PERMISSION}/${permission._id}`,
      {
        headers: {Authorization: token}
      }).then(_ => {
      setPermission({})
      setShowConfirmDeletion(false)
      getPermissions()
    })
  }

  return (
    <Container>
      <Button onClick={() => setShowCreatePermission(true)}>Create Permission</Button>
      <Table striped bordered hover variant='dark'>
        <thead>
        <tr>
          <th>ID</th>
          <th>Permission Name</th>
          <th>Description</th>
          <th>Actions</th>
        </tr>
        </thead>
        <tbody>
        {permissions.map((permission, index) => {
          return (
            <tr key={index}>
              <td>{permission._id}</td>
              <td>{permission.name}</td>
              <td>{permission.description}</td>
              <td>
                <Button className='mb-2' variant='primary'
                        onClick={() => editPermission(permission)}>Edit</Button>
                <Button variant='danger' onClick={() => confirmDeletion(permission)}>Delete</Button>
              </td>
            </tr>
          )
        })}
        </tbody>
      </Table>
      <Modal size="sm" show={showCreatePermission}
             onHide={() => setShowCreatePermission(false)}>
        <Modal.Header closeButton>
          <Modal.Title>
            Create Permission
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className='mb-3' controlId='formGroupEmail'>
              <Form.Control type='text' placeholder='Permission Name'
                            onChange={value => setPermissionName(value.target.value)}/>
              <br/>
              <Form.Control type='text' placeholder='Description'
                            onChange={value => setPermissionDescription(value.target.value)}/>
            </Form.Group>
          </Form>
        </Modal.Body>
        <Button variant='primary btn-block' style={{'border-radius': 0}} onClick={createPermission}>Create</Button>
      </Modal>

      <Modal size="sm" show={showEditPermission}
             onHide={() => setShowEditPermission(false)}>
        <Modal.Header closeButton>
          <Modal.Title>
            Edit Permission <mark>{permission.name}</mark>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className='mb-3' controlId='formGroupEmail'>
              <Form.Control type='text' placeholder='Pemission Name'
                            onChange={value => setPermissionName(value.target.value)}/>
              <br/>
              <Form.Control type='text' placeholder='Description'
                            onChange={value => setPermissionDescription(value.target.value)}/>
            </Form.Group>
          </Form>
        </Modal.Body>
        <Button variant='primary btn-block' style={{'border-radius': 0}} onClick={updatePermission}>Edit</Button>
      </Modal>

      <Modal size="sm" show={showConfirmDeletion}
             onHide={() => setShowConfirmDeletion(false)}
             aria-labelledby="example-modal-sizes-title-lg">
        <Modal.Header closeButton>
          <Modal.Title id="example-modal-sizes-title-lg">
            Confirm Deletion
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h6>Are you sure you want to delete this permission
            <mark>{permission.name}</mark>?
          </h6>
          <h6>This action CANNOT be reversed.</h6>
        </Modal.Body>
        <Button variant='danger' style={{'border-radius': 0}} onClick={deletePermission}>Delete</Button>
      </Modal>
    </Container>
  )
}